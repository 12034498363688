import { S3Object } from "../API";

export abstract class Vendor {
  id: string;
  vendorUserId: string;
  name: string;
  picture: S3Object;
  description: string;
  email: string;
  phoneNumber: string;
  address: string;
  shippingOption: string;
  sourceLink: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export class VendorModel extends Vendor {
  json: any;

  constructor(json) {
    super();
    this.json = json;
  }
}