import { DateTime } from 'luxon';

export class DateTimeFormatter {
  static format(isoDateTimeString: string) {
    const date = DateTime.fromISO(isoDateTimeString);
    return date.toFormat("dd/MM/yyyy");
  }

  static formatStringDate(isoDateTimeString: string) {
    const date = DateTime.fromISO(isoDateTimeString);
    return date.toFormat("LLL dd, yyyy");
  }

  static formatDate(isoDateTimeString: string) {
    const date = DateTime.fromISO(isoDateTimeString);
    return date.toFormat("yyyy-MM-dd");
  }

  static formatNewDate(isoDateTimeString: string) {
    const date = DateTime.fromISO(isoDateTimeString);
    return date.toFormat("yyyy/MM/dd");
  }

  static formatCreatedDate(isoDateTimeString: string) {
    const date = DateTime.fromISO(isoDateTimeString);
    return date.toFormat("yyyy/MM/dd HH:mm");
  }

  static formatCreatedDateTime(isoDateTimeString: string) {
    const date = DateTime.fromISO(isoDateTimeString);
    return date.toFormat("yyyy-MM-dd-HH:mm:ss");
  }

  static formatJapaneseDate(isoDateTimeString: string) {
    const date = DateTime.fromISO(isoDateTimeString);
    return date.toFormat("yyyy年MM月dd日");
  }

  // for input type="date"
  static split(isoDateTimeString: string) {
    const date = DateTime.fromISO(isoDateTimeString);
    return {
      date: date.toFormat("yyyy-MM-dd"),
      time: date.toFormat("HH:mm")
    };
  }

  static calculateAge(dateOfBirth: string) {
    var ageDifMs = Date.now() - DateTime.fromISO(dateOfBirth);
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
