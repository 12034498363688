class Calculations {
  
  calculateOrder(price, quantity) {
    const total = price * quantity;
    return total;
  }

  calculateServiceFee(price) {
    const total = price * 0.08;
    return total;
  }

  calculateShippingFee(price) {
    const total = price * 0.05;
    return total;
  }

  calculateTotalNoServiceFee(price): number {
    const totalPrice = price;
    return totalPrice;
  }

  // calculateTotalService(price): number {
  //   const serviceFee = 0.08;
  //   const totalPrice = price + (price * serviceFee);
  //   return totalPrice;
  // }

  calculateTotalStripeNoServiceFee(price, shipping): number {
    const totalPrice = (price + shipping) * 100;
    return totalPrice;
  }

  // calculateTotalStripe(price, shipping): number {
  //   const serviceFee = 0.08;
  //   const totalPrice = price + (price * serviceFee) + shipping;
  //   return totalPrice;
  // }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Calculations();