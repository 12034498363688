import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Button, Modal, ModalHeader, ModalBody, Label, FormGroup, Input, FormText } from 'reactstrap';
import ErrorMessageList from '../shared/ErrorMessageList';
import SpinnerInButton from '../shared/SpinnerInButton';
import GraphqlClient from '../../utils/GraphqlClient';
import { VendorModel } from '../../models/Vendor';
import S3Uploader, { S3Object } from '../../utils/S3Uploader';
import { DateTimeFormatter } from '../../utils/DateTimeFormatter';
import { UserContext } from '../../UserContext';

interface Props {
  open: boolean;
  toggle: () => void;
  vendor: VendorModel;
}

export const EditVendorModal: React.FC<Props> = (props) => {
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [fileChosen, setFileChosen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState({
    name: props.vendor.name,
    description: props.vendor.description,
    email: props.vendor.email,
    phoneNumber: props.vendor.phoneNumber,
    address: props.vendor.address,
    shippingOption: props.vendor.shippingOption,
    sourceLink: props.vendor.sourceLink
  });
  const [photoForm, setPhotoForm] = useState({
    picture: {} as S3Object,
  });

  const handleChange = event => {
    const { name, value, type } = event.target;
    const newForm = {
      ...form,
      [name]: type === 'number' ? parseFloat(value) : value
    };
    setForm(newForm);
  };

  const handleFileChange = event => {
    const picture = event.target.files[0];
    setPhotoForm({
      ...photoForm,
      picture
    });
    setFileChosen(true);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setSubmitInProgress(true);
    try {
      const { picture } = photoForm;
      const { name, description, email, phoneNumber, address, shippingOption, sourceLink } = form;
      if (fileChosen) {
        const pictureS3Object = await S3Uploader.reUploadVendorProfileImage(picture, name, DateTimeFormatter.formatCreatedDateTime(new Date().toISOString()));
        const input = {
          id: props.vendor.id,
          vendorUserId: userData?.id,
          name,
          picture: pictureS3Object,
          description,
          email,
          phoneNumber,
          address,
          shippingOption,
          sourceLink
        }
        const result = await GraphqlClient.updateVendor(input);
        const { updateVendor } = result.data;
        props.toggle();
        history.push('/');
        history.push(`/shops/${updateVendor.id}`);
      } else {
        const input = {
          id: props.vendor.id,
          vendorUserId: userData?.id,
          name,
          description,
          email,
          phoneNumber,
          address,
          shippingOption,
          sourceLink
        }
        const result = await GraphqlClient.updateVendor(input);
        const { updateVendor } = result.data;
        props.toggle();
        history.push('/');
        history.push(`/shops/${updateVendor.id}`);
      }
    } catch (err) {
      setSubmitInProgress(false);
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  };

  const title = {
    fontSize: '18px',
  };

  return (
    <>
      {props.open && (
        <Modal isOpen={props.open} toggle={props.toggle} size="lg" zIndex={1250}>
          <ModalHeader toggle={props.toggle}>
            <span style={title} className="font-weight-bold">Update {props.vendor.name} Shop Details</span>
          </ModalHeader>
          <ModalBody  className="pb-5 mb-5">
            <Container className="pb-5">
              <ErrorMessageList errors={errors} />
              <Label className="font-weight-bold mb-4">1. Upload new shop profile picture</Label>
              <FormGroup>
                <Input
                  type="file"
                  name="picture"
                  id="picture"
                  onChange={handleFileChange}
                >
                </Input>
                <FormText>
                  We only support jpg and png file only.
                </FormText>
              </FormGroup>
              <Label className="font-weight-bold mt-5 mb-4">2. Shop name</Label>
              <FormGroup>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={form.name}
                  onChange={handleChange}
                />
              </FormGroup>

              <Label className="font-weight-bold mt-5 mb-4">3. Description</Label>
              <FormGroup>
                <Input
                  type="textarea"
                  rows="7"
                  name="description"
                  placeholder="Introduce your shop"
                  id="description"
                  className="h-100"
                  value={form.description}
                  onChange={handleChange}
                />
              </FormGroup>

              <Label className="font-weight-bold mt-5">4. Email address</Label>
              <p className="grey-color mb-4">We will not share your email address.</p>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={form.email}
                  onChange={handleChange}
                />
              </FormGroup>

              <Label className="font-weight-bold mt-5">5. Phone number</Label>
              <p className="grey-color mb-4">We will not share your phone number.</p>
              <FormGroup>
                <Input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={form.phoneNumber}
                  onChange={handleChange}
                />
              </FormGroup>

              <Label className="font-weight-bold mt-5">6. Address</Label>
              <FormGroup>
                <Input
                  type="textarea"
                  rows="7"
                  name="address"
                  placeholder="Your main location"
                  id="address"
                  className="h-100"
                  value={form.address}
                  onChange={handleChange}
                />
              </FormGroup>

              <Label className="font-weight-bold mt-5">7. Shipping option</Label>          
              <p className="grey-color mb-4">This option will let the users choose during checkout.</p>
              <FormGroup>
                <Input
                  type="select"
                  id="shippingOption"
                  name="shippingOption"
                  value={form.shippingOption}
                  onChange={handleChange}
                >
                  <option></option>
                  <option value="self" key="self">Self-pickup</option>
                  <option value="postage" key="postage">Postage</option>
                </Input>
              </FormGroup>

              {(userData.userType === "admin") && (
                <>
                  <Label className="font-weight-bold mt-5">8. Vendor Source Link</Label>          
                  <p className="grey-color mb-4">Only visible to admin only.</p>
                  <FormGroup>
                    <Input
                      type="text"
                      name="sourceLink"
                      id="sourceLink"
                      value={form.sourceLink}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </>
              )}

              <div className="mt-5 text-right">
                <Button
                  className="primary-btn"
                  style={{ fontSize: '18px' }}
                  onClick={handleSubmit}
                >
                  <SpinnerInButton loading={submitInProgress} text="Update my shop" />
                  {submitInProgress && (<span className="ml-3">Updating</span>)}
                </Button>
              </div>
            </Container>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}