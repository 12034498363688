import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import CreateVendorPage from '../components/vendor/CreateVendorPage';
import UserOrderPage from '../components/order/UserOrderPage';
import AdminOrderPage from '../components/order/AdminOrderPage';

const AuthRoutes = () => {
  return (
    <>
      <Route exact path="/create-shop" component={CreateVendorPage} />
      <Route exact path="/all-orders" component={AdminOrderPage} />
      <Route exact path="/orders" component={UserOrderPage} />

      <Route exact path="/login" render={() => <Redirect to="/" />} />
    </>
  );
};

export default AuthRoutes;