import React from 'react';
import { Spinner } from 'reactstrap';

const SpinnerInButton = ({ loading, text }) => {
  if (loading) {
    return (
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    );
  }
  return text;
};

export default SpinnerInButton;