import React from 'react';
import { Card, CardBody, CardFooter, Col } from 'reactstrap';
import { ProductModel } from '../../models/Product';
import { Link } from 'react-router-dom';

type Props = {
  product: ProductModel;
}

export const VendorProduct = ({ product }: Props) => {

  // const serviceFee = Calculations.calculateServiceFee(product.wholesalePrice);
  // const totalServiceFee = product.wholesalePrice + serviceFee;

  return(
    <>
      <Col md={3} sm={12} xs={12} className="mb-5">
        <Card>
          <Link to={`/products/${product.id}`} style={{ textDecoration: 'none' }}>
            <img
              src={`https://${product.productPhoto.bucket}.s3.amazonaws.com/public/${product.productPhoto.key}`} 
              alt={product.title}
              style={{ width: '100%', maxHeight: '250px', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
            />
            <CardBody style={{ minHeight: '180px' }}>
              <h3 style={{ fontSize: '20px' }}>
                <Link to={`/products/${product.id}`} className='font-weight-bold link-text-secondary'>
                  {product.title.slice(0,40) + (product.title.length > 40 ? " ..." : "")}
                </Link>
              </h3>
              <h4 className='mt-3 grey-color' style={{ fontSize: '14px' }}>
                {product.description.slice(0,90) + (product.description.length > 90 ? " ..." : "")}
              </h4>
            </CardBody>
          </Link>
          <CardFooter>
            <div className='pt-3'>
              <h5 className='font-weight-bold' style={{ fontSize: '28px' }}>
                RM {product.wholesalePrice.toFixed(2)}
              </h5>
              {product.pricePerUnit && (
                <h5 className='mt-2 grey-color font-weight-normal' style={{ fontSize: '14px' }}>
                  RM {product.pricePerUnit.toFixed(2)} / unit
                </h5>
              )}
            </div>
          </CardFooter>
        </Card>
      </Col>
    </>
  )
}
