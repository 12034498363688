import React, { useEffect, useState, useContext } from 'react';
import { Alert, Row } from 'reactstrap';
import { VendorModel } from '../../models/Vendor';
import GraphqlClient from '../../utils/GraphqlClient';
import { modelFactory } from '../../models/ModelFactory';
import { VendorCard } from './VendorCard';
import { ProductModel } from '../../models/Product';
import { ProductCard } from './ProductCard';
import Header from '../header/Header';
import { UserContext } from '../../UserContext';
import AuthNavbar from '../header/AuthNavbar';

const LandingPage = () => {
  const { userData } = useContext(UserContext);
  const [allVendors, setAllVendors] = useState<VendorModel[]>(null);
  const [allProducts, setAllProducts] = useState<ProductModel[]>(null);

  useEffect(() => {
    const listProducts = async () => {
      const filter = {
        active: { ne: false }
      };
      const results = await Promise.all([
        GraphqlClient.listProducts(filter)
      ]);
      const allProducts = results[0].map(i => modelFactory<ProductModel>(i, ProductModel))
      .sort((a, b) => {
        if (b.title > a.title) return -1;
        if (b.title < a.title) return 1;
        return 0;
      });
      setAllProducts(allProducts);
    };
    listProducts();
  }, []);

  useEffect(() => {
    const listVendors = async () => {
      const filter = {
        active: { ne: false }
      };
      const results = await Promise.all([
        GraphqlClient.listVendors(filter)
      ]);
      const allVendors = results[0].map(i => modelFactory<VendorModel>(i, VendorModel))
      .sort((a, b) => {
        if (b.name > a.name) return -1;
        if (b.name < a.name) return 1;
        return 0;
      });
      setAllVendors(allVendors);
    };
    listVendors();
  }, []);

  return(
    <>
      {userData && (
        <AuthNavbar />
      )}
      {!userData && (
        <Header />
      )}
      <div className='container-fluid pl-5 pr-5'>
        <h1>
          Bababomb
        </h1>
        <div className='pt-5 mt-5'>
          <Alert color='info'>
            Didn’t see what you’re looking for? Send the product images to hello@bababom.com or whatsapp +60173573272 and we will feature your products within 12 hours free of charge. 
          </Alert>
        </div>
        <div className='pt-5 mt-5'>
          <h2 style={{ color: '#000', fontFamily: '"Montserrat", sans-serif', fontSize: '32px', fontWeight: 600 }}>
            Product List
          </h2>
          <div className='divider'></div>
          <Row className='pt-5'>
            {allProducts && allProducts.map(product => (
              <ProductCard
                product={product}
                key={product.id}
              />
            ))}
          </Row>
        </div>
        <div className='pt-5'>
          <h2 style={{ color: '#000', fontFamily: '"Montserrat", sans-serif', fontSize: '32px', fontWeight: 600 }}>
            Shop List
          </h2>
          <div className='divider'></div>
          <Row className='pt-5'>
            {allVendors && allVendors.map(vendor => (
              <VendorCard
                vendor={vendor}
                key={vendor.id}
              />
            ))}
          </Row>
        </div>
      </div>
    </>
  )
}

export default LandingPage;