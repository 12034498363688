import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { VendorModel } from '../../models/Vendor';

type Props = {
  vendor: VendorModel;
}

export const VendorCard = ({ vendor }: Props) => {

  return(
    <>
      <Col md={3} sm={12} xs={12} className="mb-5">
        <Card>
          <Link to={`/shops/${vendor.id}`} style={{ textDecoration: 'none' }}>
            <img
              src={`https://${vendor.picture.bucket}.s3.amazonaws.com/public/${vendor.picture.key}`} 
              alt={vendor.name}
              style={{ width: '100%', maxHeight: '320px', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
            />
            <CardBody style={{ minHeight: '180px' }}>
              <h3 style={{ fontSize: '20px' }}>
                <Link to={`/shops/${vendor.id}`} className='font-weight-bold link-text-secondary'>{vendor.name}</Link>
              </h3>

              <h4 className='mt-3 grey-color' style={{ fontSize: '14px' }}>
                {vendor.description.slice(0,200) + (vendor.description.length > 200 ? " ..." : "")}
              </h4>
            </CardBody>
          </Link>
        </Card>
      </Col>
    </>
  )
}
