import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { VendorModel } from '../../models/Vendor';

type Props = {
  vendor: VendorModel;
  index: number;
}

export const VendorListTable = ({ vendor, index }: Props) => {

  return(
    <>
      <tr>
        <td className='text-center'>{index +1}</td>
        <td className="text-uppercase font-weight-bold">{vendor.name}</td>
        <td>{vendor.email}</td>
        <td>{vendor.phoneNumber}</td>
        <td>{vendor.address}</td>
        <td className='text-capitalize'>{vendor.shippingOption}</td>
        <td className="text-center">
          <Link to={`/shops/${vendor.id}`} rel="noopener noreferrer" target="_blank">
            <Button
              className="white-btn"
              style={{ borderRadius: "10px" }}
            >
              View
            </Button>
          </Link>
        </td>
      </tr>
    </>
  )
}