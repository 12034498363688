import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import GraphqlClient from '../../utils/GraphqlClient';
import { modelFactory } from '../../models/ModelFactory';
import { UserContext } from '../../UserContext';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { VendorModel } from '../../models/Vendor';
import { AddProductModal } from '../product/AddProductModal';
import { ProductModel } from '../../models/Product';
import { VendorProduct } from '../product/VendorProduct';
import AuthNavbar from '../header/AuthNavbar';
import Header from '../header/Header';
import LoadingSpinner from '../shared/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { EditVendorModal } from './EditVendorModal';
import { ArchiveVendorModal } from './ArchiveVendorModal';

interface Props extends RouteComponentProps<{ id: string; }> {
}

export const VendorDetailsPage: React.FC<Props> = (props) => {
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const [errors, setErrors] = useState([]);
  const [vendor, setVendor] = useState<VendorModel>(undefined);
  const [vendorProducts, setVendorProducts] = useState<ProductModel[]>(undefined);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openVendorModal, setOpenVendorModal] = useState(false);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);

  const toggleArchiveModal = () => {
    setOpenArchiveModal(!openArchiveModal);
  };

  const toggleProductModal = () => {
    setOpenProductModal(!openProductModal);
  };

  const toggleVendorModal = () => {
    setOpenVendorModal(!openVendorModal);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      (async () => {
        const vendorId = props.match.params.id;
        const result = await GraphqlClient.getVendor(vendorId);
        const vendor = modelFactory<VendorModel>(
          result.data.getVendor,
          VendorModel
        );
        setVendor(vendor);
      })();
    } catch (err) {
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  }, [props.match.params.id, errors, history]);

  useEffect(() => {
    try {
      (async () => {
        const vendorId = props.match.params.id;
        const vendorFilter = {
          vendorId: { eq: vendorId },
          active: { ne: false }
        };
        const results = await Promise.all([
          GraphqlClient.listProducts(vendorFilter)
        ]);
        const vendorProducts = results[0].map(i =>
          modelFactory<ProductModel>(i, ProductModel)
        );
        setVendorProducts(vendorProducts);
      })();
    } catch (err) {
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  if (!vendor || !vendorProducts) {
    return <LoadingSpinner />
  }

  return(
    <>
      {userData && (
        <AuthNavbar />
      )}
      {!userData && (
        <Header />
      )}
      <Container className='pt-5 mt-5 pb-5'>
        <h2 style={{ color: '#000', fontFamily: '"Montserrat", sans-serif', fontSize: '40px', fontWeight: 600 }} className='pt-5 mt-5'>{vendor.name}</h2>
        <div className='divider'></div>
        <Row className='pb-5'>
          <Col md={3} className='pb-5'>
            {(vendor.picture) && (
              <>
                <img
                  src={`https://${vendor.picture.bucket}.s3.amazonaws.com/public/${vendor.picture.key}`} 
                  alt={vendor.name}
                  style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
                />
              </>
            )}
            <Row className='pt-3'>
              <Col xs={6}>
                {userData && ((userData.id === vendor.vendorUserId) || (userData.userType === "admin")) && (
                  <div className='mt-3 text-center' onClick={toggleVendorModal}>
                    <p style={{ cursor: 'pointer' }}>
                      <span className="mr-3"><FontAwesomeIcon icon={faEdit} /></span>
                        Edit
                    </p>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                {userData && (userData.userType === "admin") && (
                  <div className='mt-3 text-center' onClick={toggleArchiveModal}>
                    <p style={{ cursor: 'pointer' }}>
                      <span className="mr-3"><FontAwesomeIcon icon={faTrash} /></span>
                        Archive
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={1} className='pb-5'></Col>
          <Col md={8} className='pb-5'>
            <div>
              <p className='font-weight-bold grey-color' style={{ fontSize: '16px' }}>Description</p>
              <h5 style={{ color: '#000', fontSize: '18px', fontWeight: 500 }} className='grey-color'>{vendor.description}</h5>

              <p className='font-weight-bold grey-color mt-5'>Address</p>
              <h5 style={{ color: '#000', fontSize: '18px', fontWeight: 500 }} className='grey-color'>{vendor.address}</h5>

              <p className='font-weight-bold grey-color mt-5'>Shipping Option</p>
              <h5 style={{ color: '#000', fontSize: '18px', fontWeight: 500 }} className='grey-color text-capitalize'>{vendor.shippingOption}</h5>
            </div>
          </Col>
        </Row>
        <h4 className='font-weight-bold grey-color pt-5 pb-4'>Products</h4>
        <div>
          {userData && (userData.isAdmin || (userData.id === vendor.vendorUserId)) && (
            <Button
              className="primary-btn"
              style={{ width: '200px' }}
              onClick={toggleProductModal}
            >
              <span className="mr-3"><FontAwesomeIcon icon={faPlusCircle} /></span>
              Add new product
            </Button>
          )}
        </div>
        <Row className='pt-5'>
          {vendorProducts && vendorProducts.map(product => (
            <VendorProduct
              product={product}
              key={product.id}
            />
          ))}
        </Row>
      </Container>
      <AddProductModal 
        open={openProductModal}
        toggle={toggleProductModal}
        vendor={vendor}
      />
      <EditVendorModal 
        open={openVendorModal}
        toggle={toggleVendorModal}
        vendor={vendor}
      />
      <ArchiveVendorModal 
        open={openArchiveModal}
        toggle={toggleArchiveModal}
        vendorId={vendor.id}
        name={vendor.name}
      />
    </>
  )
}