/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      fullName
      phoneNumber
      userType
      vendorId
      stripeAccountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getVendor = /* GraphQL */ `
  query GetVendor($id: ID!) {
    getVendor(id: $id) {
      id
      vendorUserId
      name
      picture {
        bucket
        region
        key
        __typename
      }
      description
      email
      phoneNumber
      address
      shippingOption
      sourceLink
      active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVendors = /* GraphQL */ `
  query ListVendors(
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vendorUserId
        name
        picture {
          bucket
          region
          key
          __typename
        }
        description
        email
        phoneNumber
        address
        shippingOption
        sourceLink
        active
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      vendorUserId
      vendorId
      stripeProductId
      stripePriceId
      productPhoto {
        bucket
        region
        key
        __typename
      }
      productPhoto2 {
        bucket
        region
        key
        __typename
      }
      productPhoto3 {
        bucket
        region
        key
        __typename
      }
      productPhoto4 {
        bucket
        region
        key
        __typename
      }
      title
      brandName
      description
      weight
      retailPrice
      wholesalePrice
      pricePerUnit
      shippingFee
      unitPerPackaging
      minQuantity
      category
      notes
      active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vendorUserId
        vendorId
        stripeProductId
        stripePriceId
        productPhoto {
          bucket
          region
          key
          __typename
        }
        productPhoto2 {
          bucket
          region
          key
          __typename
        }
        productPhoto3 {
          bucket
          region
          key
          __typename
        }
        productPhoto4 {
          bucket
          region
          key
          __typename
        }
        title
        brandName
        description
        weight
        retailPrice
        wholesalePrice
        pricePerUnit
        shippingFee
        unitPerPackaging
        minQuantity
        category
        notes
        active
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      vendorId
      vendorName
      vendorEmail
      vendorPhoneNumber
      name
      userId
      email
      phoneNumber
      address
      shippingOption
      shippingStatus
      productId
      stripeProductId
      title
      notesToVendor
      retailPrice
      wholesalePrice
      quantity
      serviceFee
      shippingFee
      totalPrice
      paymentStatus
      active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vendorId
        vendorName
        vendorEmail
        vendorPhoneNumber
        name
        userId
        email
        phoneNumber
        address
        shippingOption
        shippingStatus
        productId
        stripeProductId
        title
        notesToVendor
        retailPrice
        wholesalePrice
        quantity
        serviceFee
        shippingFee
        totalPrice
        paymentStatus
        active
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      orderId
      productId
      productName
      vendorId
      vendorName
      clientId
      paymentStatus
      totalPrice
      serviceFee
      shippingFee
      orderPrice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        productId
        productName
        vendorId
        vendorName
        clientId
        paymentStatus
        totalPrice
        serviceFee
        shippingFee
        orderPrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
