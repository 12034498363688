/* eslint-disable import/no-anonymous-default-export */
import { Storage } from 'aws-amplify';
import aws_exports from '../aws-exports';

class S3Uploader {

  private FILE_SUFFIX = "-original";
  private SUFFIX_PATTERN = new RegExp(`${this.FILE_SUFFIX}$`);

  getExtension(filename: string) {
    return filename.split(".").pop();
  }

  async uploadVendorProfileImage(file, name: string, date: string) {
    const key = `files/shops/${date}-${name}`;
    return await this.uploadFile(file, key);
  }

  async reUploadVendorProfileImage(file, name: string, date: string) {
    const key = `files/shops/${date}-${name}`;
    await this.remove(key);
    const uploadKey = `${key}`;
    return await this.uploadFile(file, uploadKey);
  }

  async uploadProductImage(file, name: string, date: string) {
    const key = `files/products/${date}-${name}`;
    return await this.uploadFile(file, key);
  }

  async reUploadProductImage(file, name: string, date: string) {
    const key = `files/products/${date}-${name}`;
    await this.remove(key);
    const uploadKey = `${key}`;
    return await this.uploadFile(file, uploadKey);
  }

  private async uploadFile(file, filename: string): Promise<S3Object> {
    const uploadedFile :any = await Storage.put(filename, file, {
      contentType: file.type,
    });
    const key = uploadedFile.key.replace(this.SUFFIX_PATTERN, "");
    const s3Object = {
      key: key,
      bucket: aws_exports.aws_user_files_s3_bucket,
      region: aws_exports.aws_project_region
    };
    return s3Object;
  }

  async remove(key: string) {
    return await Storage.remove(key);
  }
}

export default new S3Uploader();

export type PickedData = {
  file: File;
  name: string;
  size: number;
  type: string;
};

export type S3Object = {
  key: string;
  bucket: string;
  region: string;
};

export function isPickedData(data: any): data is PickedData {
  return data.file !== undefined
    && data.name !== undefined
    && data.size !== undefined
    && data.type !== undefined;
}

export function isS3Object(data: any): data is S3Object {
  return data.key !== undefined;
}