import React, { useState, useContext } from 'react';
import { Container, Button, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import GraphqlClient from '../../utils/GraphqlClient';
import { VendorModel } from '../../models/Vendor';
import { ProductModel } from '../../models/Product';
import Calculations from '../../utils/Calculations';
import StripeRestClient from '../../utils/StripeRestClient';
import { UserContext } from '../../UserContext';
import SpinnerInButton from '../shared/SpinnerInButton';

interface Props {
  open: boolean;
  toggle: () => void;
  vendor: VendorModel;
  product: ProductModel;
  notesToVendor: string;
  orderQuantity: number;
  submitInProgress: () => void;
}

export const OrderConfirmationModal: React.FC<Props> = (props) => {
  const { userData } = useContext(UserContext);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form] = useState({
    vendorId: props.vendor.id,
    vendorName: props.vendor.name,
    email: props.vendor.email,
    phoneNumber: props.vendor.phoneNumber,
    address: props.vendor.address,
    shippingOption: props.vendor.shippingOption,
    productId: props.product.id,
    title: props.product.title,
    retailPrice: props.product.retailPrice,
    wholesalePrice: props.product.wholesalePrice,
    shippingFee: props.product.shippingFee ? props.product.shippingFee : 1,
    quantity: props.orderQuantity,
    stripeProductId: props.product.stripeProductId,
    stripePriceId: props.product.stripePriceId
  });

  const totalPrice = Calculations.calculateOrder(props.product.wholesalePrice, props.orderQuantity);
  // const serviceFee = Calculations.calculateServiceFee(totalPrice);
  // const totalServiceFee = totalPrice + serviceFee;
  const totalOrderPrice = totalPrice + form.shippingFee;

  const handleSave = async event => {
    event.preventDefault();
    setSubmitInProgress(true);
    try {
      const { vendorId, vendorName, email, phoneNumber, shippingOption, productId, title, retailPrice, wholesalePrice, shippingFee } = form;
      if (!shippingFee) {
        const tempShippingFee = 1;
        const priceRequestParam = {
          priceId: props.product.stripePriceId,
          wholesalePrice: Calculations.calculateTotalStripeNoServiceFee(wholesalePrice, tempShippingFee),
          productId: props.product.stripeProductId
        };
        await StripeRestClient.updatePrice(props.product.stripePriceId, priceRequestParam);
      }
      const input = {
        vendorId,
        vendorName,
        vendorEmail: email,
        vendorPhoneNumber: phoneNumber,
        name: userData.fullName,
        userId: userData?.id,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        shippingOption,
        shippingStatus: "pending",
        productId,
        title,
        retailPrice,
        wholesalePrice,
        quantity: props.orderQuantity,
        shippingFee,
        totalPrice: totalOrderPrice,
        paymentStatus: "pending"
      };
      const linkInput = {
        priceId: form.stripePriceId,
        price: totalOrderPrice,
        quantity: props.orderQuantity
      }
      const paymentLink = await StripeRestClient.createPaymentLink(linkInput);
      await GraphqlClient.createOrder(input);
      window.open(`${paymentLink.stripePaymentUrl}`, '_self', 'noopener, noreferrer');
      props.toggle();
    } catch (err) {
      setSubmitInProgress(false);
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  };

  const handleCancel = () => {
    props.toggle();
    props.submitInProgress();
  };

  const title = {
    fontSize: '18px',
  };

  return (
    <>
      {props.open && (
        <Modal isOpen={props.open} toggle={props.toggle} size="md" zIndex={1250}>
          <ModalHeader toggle={props.toggle}>
            <span style={title} className="font-weight-bold">Confirm your order</span>
          </ModalHeader>
          <ModalBody  className="pb-5 mb-5">
            <Container>
              <h5 className='grey-color font-weight-bold mt-3' style={{ fontSize: '16px' }}>Product name</h5>
              <h3 style={{ fontSize: '20px'}} className='font-weight-bold'>
                {props.product.title}
              </h3>

              <h5 className='grey-color font-weight-bold mt-5' style={{ fontSize: '16px' }}>Notes to {props.vendor.name} shop</h5>
              {props.notesToVendor && (
                <h4 className='font-weight-bold' style={{ fontSize: '20px'}}>
                  {props.notesToVendor}
                </h4>
              )}
              {!props.notesToVendor && (
                <h4 className='font-weight-bold' style={{ fontSize: '20px'}}>
                  -
                </h4>
              )}

              <h5 className='grey-color font-weight-bold mt-5' style={{ fontSize: '16px' }}>Order quantity</h5>
              <h4 className='font-weight-bold' style={{ fontSize: '20px'}}>
                {props.orderQuantity} packages
              </h4>

              <h5 className='grey-color font-weight-bold mt-5' style={{ fontSize: '16px' }}>Total price</h5>
              <h4 className='font-weight-bold' style={{ fontSize: '20px'}}>
                RM {totalOrderPrice.toFixed(2)}
              </h4>
              <h5 className='grey-color mt-5' style={{ fontSize: '16px', textDecoration: 'underline' }}>
                Price details
              </h5>
              <h5 className='grey-color mt-3' style={{ fontSize: '16px' }}>
                Order price: RM {props.product.wholesalePrice.toFixed(2)} / package
              </h5>
              <h5 className='grey-color' style={{ fontSize: '16px' }}>
                Shipping fee: RM {form.shippingFee.toFixed(2)}
              </h5>
              {/* <h5 className='grey-color' style={{ fontSize: '16px' }}>
                Service fee: RM {serviceFee.toFixed(2)}
              </h5> */}

              <div className='mt-5 pt-5'>
                {!submitInProgress && (
                  <Row>
                    <Col>
                      <Button
                        className="primary-btn"
                        style={{ width: '100%' }}
                        onClick={handleSave}
                        disabled={submitInProgress}
                      >
                        <SpinnerInButton loading={submitInProgress} text="Pay now" />
                        {submitInProgress && (<span className="ml-3">Redirecting</span>)}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={handleCancel}
                        style={{ width: '100%' }}
                        className="cancel-btn"
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                )}
                {submitInProgress && (
                  <Button
                    className="primary-btn"
                    style={{ width: '100%' }}
                    onClick={handleSave}
                    disabled={submitInProgress}
                  >
                    <SpinnerInButton loading={submitInProgress} text="Pay now" />
                    {submitInProgress && (<span className="ml-3">Redirecting</span>)}
                  </Button>
                )}
              </div>
            </Container>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}