import React, { useState, useContext } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Container, Form, FormGroup, FormText, Input, Label } from "reactstrap";
import S3Uploader, { S3Object } from '../../utils/S3Uploader';
import SpinnerInButton from "../shared/SpinnerInButton";
import GraphqlClient from "../../utils/GraphqlClient";
import { DateTimeFormatter } from "../../utils/DateTimeFormatter";
import AuthNavbar from "../header/AuthNavbar";
import { UserContext } from "../../UserContext";

const CreateVendorPage = () => {
  const history = useHistory();
  const { userData, setUserData } = useContext(UserContext);
  const [errors, setErrors] = useState([]);
  const [fileChosen, setFileChosen] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [form, setForm] = useState({
    name: "",
    description: "",
    email: "",
    phoneNumber: "",
    address: "",
    shippingOption: "",
    sourceLink: ""
  });
  const [photoForm, setPhotoForm] = useState({
    picture: {} as S3Object,
  });

  const handleChange = event => {
    const { name, value } = event.target;
    const newForm = {
      ...form,
      [name]: value
    };
    setForm(newForm);
  };

  const handleFileChange = event => {
    const picture = event.target.files[0];
    setPhotoForm({
      ...photoForm,
      picture
    });
    setFileChosen(true);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setInProgress(true);
    try {
      const { picture } = photoForm;
      const { name, description, email, phoneNumber, address, shippingOption, sourceLink } = form;
      const pictureS3Object = await S3Uploader.uploadVendorProfileImage(picture, name, DateTimeFormatter.formatCreatedDateTime(new Date().toISOString()));
      const input = {
        vendorUserId: userData?.id,
        name,
        picture: pictureS3Object,
        description,
        email,
        phoneNumber,
        address,
        shippingOption,
        sourceLink
      }
      const result = await GraphqlClient.createVendor(input);
      const { createVendor } = result.data;
      if (userData.userType === "admin") {
        history.push('/');
        history.push('/shops');
      } else {
        const userInput = {
          id: userData.id,
          vendorId: createVendor.id
        }
        const userResult = await GraphqlClient.updateUser(userInput);
        const { updateUser } = userResult.data;
        setUserData(updateUser);
        history.push('/');
        history.push(`/shops/${createVendor.id}`);
      }
    } catch (err) {
      setInProgress(false);
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  };

  return(
    <>
      <AuthNavbar />
      <Container className="pt-5 mt-5 pb-5">
        <h2 className="mt-5 pt-5" style={{ color: '#000', fontFamily: '"Montserrat", sans-serif', fontWeight: 600 }}>Create Your Shop</h2>
        <p className="grey-color">It will take you less than 5 minutes to setup a shop page.</p>
        <p className="grey-color pb-4">
          Note: 
          <span className="danger"> * </span>
          Must be filled
        </p>
        <div className="divider pt-3 mb-5"></div>
        <Form>
          <Label className="font-weight-bold mb-4">
            1. Shop Profile Picture
            <span className="danger"> *</span>
          </Label>
          <FormGroup>
            <Input
              type="file"
              name="picture"
              id="picture"
              onChange={handleFileChange}
            >
            </Input>
            <FormText>
              We only support jpg and png file only.
            </FormText>
          </FormGroup>
          <Label className="font-weight-bold mt-5 mb-4">
            2. Shop Name
            <span className="danger"> *</span>
          </Label>
          <FormGroup>
            <Input
              type="text"
              name="name"
              id="name"
              value={form.name}
              onChange={handleChange}
            />
          </FormGroup>

          <Label className="font-weight-bold mt-5 mb-4">3. Description</Label>
          <FormGroup>
            <Input
              type="textarea"
              rows="7"
              name="description"
              placeholder="Introduce your shop"
              id="description"
              className="h-100"
              value={form.description}
              onChange={handleChange}
            />
          </FormGroup>

          <Label className="font-weight-bold mt-5">4. Email Address</Label>
          <p className="grey-color mb-4">We will not share your email address.</p>
          <FormGroup>
            <Input
              type="email"
              name="email"
              id="email"
              value={form.email}
              onChange={handleChange}
            />
          </FormGroup>

          <Label className="font-weight-bold mt-5">5. Phone Number</Label>
          <p className="grey-color mb-4">We will not share your phone number.</p>
          <FormGroup>
            <Input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={form.phoneNumber}
              onChange={handleChange}
            />
          </FormGroup>

          <Label className="font-weight-bold mt-5">6. Address</Label>
          <FormGroup>
            <Input
              type="textarea"
              rows="7"
              name="address"
              placeholder="Your main location"
              id="address"
              className="h-100"
              value={form.address}
              onChange={handleChange}
            />
          </FormGroup>

          <Label className="font-weight-bold mt-5">7. Shipping Option</Label>          
          <p className="grey-color mb-4">This option will let the users choose during checkout.</p>
          <FormGroup>
            <Input
              type="select"
              id="shippingOption"
              name="shippingOption"
              value={form.shippingOption}
              onChange={handleChange}
            >
              <option></option>
              <option value="self" key="self">Self-pickup</option>
              <option value="postage" key="postage">Postage</option>
            </Input>
          </FormGroup>

          {(userData.userType === "admin") && (
            <>
              <Label className="font-weight-bold mt-5">8. Vendor Source Link</Label>          
              <p className="grey-color mb-4">Only visible to admin only.</p>
              <FormGroup>
                <Input
                  type="text"
                  id="sourceLink"
                  name="sourceLink"
                  value={form.sourceLink}
                  onChange={handleChange}
                />
              </FormGroup>
            </>
          )}

          {(fileChosen) && (
            <div className="mt-5 text-right">
              <Button
                className="primary-btn"
                style={{ fontSize: '18px' }}
                onClick={handleSubmit}
              >
                <SpinnerInButton loading={inProgress} text="Create my shop" />
                {inProgress && (<span className="ml-3">Saving</span>)}
              </Button>
            </div>
          )}
        </Form>
      </Container>
    </>
  )
}

export default CreateVendorPage;