export abstract class Order {
  id: string;
  vendorId: string;
  vendorName: string;
  vendorEmail: string;
  vendorPhoneNumber: string;
  name: string;
  userId: string;
  email: string;
  phoneNumber: string;
  address: string;
  shippingOption: string;
  shippingStatus: string;
  productId: string;
  stripeProductId: string;
  title: string;
  notesToVendor: string;
  retailPrice: number;
  wholesalePrice: number;
  quantity: number;
  serviceFee: number;
  shippingFee: number;
  totalPrice: number;
  paymentStatus: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export class OrderModel extends Order {
  json: any;

  constructor(json) {
    super();
    this.json = json;
  }
}