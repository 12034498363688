import React, { useState } from 'react';
import { Container, Button, Modal, ModalHeader, ModalBody, Row, Col, Label, FormGroup, Input } from 'reactstrap';
import GraphqlClient from '../../utils/GraphqlClient';
import SpinnerInButton from '../shared/SpinnerInButton';
import { OrderModel } from '../../models/Order';
import { useHistory } from 'react-router-dom';

interface Props {
  open: boolean;
  toggle: () => void;
  order: OrderModel;
}

export const AdminOrderUpdateModal: React.FC<Props> = (props) => {
  const history = useHistory();
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState({
    vendorId: props.order.vendorId,
    vendorName: props.order.vendorName,
    vendorEmail: props.order.vendorEmail,
    vendorPhoneNumber: props.order.vendorPhoneNumber,
    name: props.order.name,
    userId: props.order.userId,
    email: props.order.email,
    phoneNumber: props.order.phoneNumber,
    address: props.order.address,
    shippingOption: props.order.shippingOption,
    shippingStatus: props.order.shippingStatus,
    productId: props.order.productId,
    stripeProductId: props.order.stripeProductId,
    title: props.order.title,
    notesToVendor: props.order.notesToVendor,
    retailPrice: props.order.retailPrice,
    wholesalePrice: props.order.wholesalePrice,
    quantity: props.order.quantity,
    serviceFee: props.order.serviceFee,
    shippingFee: props.order.shippingFee,
    totalPrice: props.order.totalPrice,
    paymentStatus: props.order.paymentStatus
  });

  const handleChange = event => {
    const { name, value, type } = event.target;
    const newForm = {
      ...form,
      [name]: type === 'number' ? parseFloat(value) : value
    };
    setForm(newForm);
  };

  const handleSave = async event => {
    event.preventDefault();
    setSubmitInProgress(true);
    try {
      const { shippingStatus, paymentStatus, name, email, phoneNumber, notesToVendor, address, shippingOption } = form;
      const input = {
        id: props.order.id,
        shippingStatus,
        paymentStatus,
        name,
        email,
        phoneNumber,
        notesToVendor,
        address,
        shippingOption
      };
      await GraphqlClient.updateOrder(input);
      props.toggle();
      history.push('/');
      history.push("/all-orders");
    } catch (err) {
      setSubmitInProgress(false);
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  };

  const handleArchive = async event => {
    event.preventDefault();
    setSubmitInProgress(true);
    try {
      const input = {
        id: props.order.id,
        active: false
      };
      await GraphqlClient.updateOrder(input);
      props.toggle();
      history.push('/');
      history.push("/all-orders");
    } catch (err) {
      setSubmitInProgress(false);
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  };

  const handleCancel = () => {
    props.toggle();
  };

  const title = {
    fontSize: '18px',
  };

  return (
    <>
      {props.open && (
        <Modal isOpen={props.open} toggle={props.toggle} size="lg" zIndex={1250}>
          <ModalHeader toggle={props.toggle}>
            <span style={title} className="font-weight-bold">Update order details</span>
          </ModalHeader>
          <ModalBody  className="pb-5 mb-5">
            <Container>

              <Label className="font-weight-bold mt-3">Shipping Status</Label>
              <FormGroup>
                <Input
                  type="select"
                  name="shippingStatus"
                  id="shippingStatus"
                  value={form.shippingStatus}
                  onChange={handleChange}
                >
                  <option key="pending" value="pending">Pending</option>
                  <option key="shipped" value="shipped">Shipped</option>
                  <option key="delivered" value="delivered">Delivered</option>
                  <option key="unsuccessful" value="unsuccessful">Delivery Unsuccessful</option>
                </Input>
              </FormGroup>

              <Label className="font-weight-bold mt-3">Payment Status</Label>
              <FormGroup>
                <Input
                  type="select"
                  name="paymentStatus"
                  id="paymentStatus"
                  value={form.paymentStatus}
                  onChange={handleChange}
                >
                  <option key="pending" value="pending">Pending</option>
                  <option key="confirmation" value="confirmation">Confirmation With User</option>
                  <option key="successful" value="successful">Successful</option>
                  <option key="failed" value="failed">Failed</option>
                </Input>
              </FormGroup>

              <Label className="font-weight-bold mt-3">Product name</Label>
              <FormGroup>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  value={form.title}
                  readOnly
                />
              </FormGroup>

              <div className='pt-3 pb-3'>
                <Button
                  className="primary-btn"
                  style={{ width: '100%' }}
                  onClick={handleArchive}
                  disabled={submitInProgress}
                >
                  <SpinnerInButton loading={submitInProgress} text="Archive order" />
                  {submitInProgress && (<span className="ml-3">Updating</span>)}
                </Button>
              </div>

              <h5 className='mt-5 font-weight-bold' style={{ fontSize: '20px' }}>Shop Details</h5>
              <div className='divider'></div>

              <Label className="font-weight-bold mt-3">Shop name</Label>
              <FormGroup>
                <Input
                  type="text"
                  name="vendorName"
                  id="vendorName"
                  value={form.vendorName}
                  readOnly
                />
              </FormGroup>

              <Label className="font-weight-bold mt-3">Shop Email</Label>
              <FormGroup>
                <Input
                  type="text"
                  name="vendorEmail"
                  id="vendorEmail"
                  value={form.vendorEmail}
                  readOnly
                />
              </FormGroup>

              <Label className="font-weight-bold mt-3">Shop Phone Number</Label>
              <FormGroup>
                <Input
                  type="text"
                  name="vendorPhoneNumber"
                  id="vendorPhoneNumber"
                  value={form.vendorPhoneNumber}
                  readOnly
                />
              </FormGroup>

              <h5 className='mt-5 font-weight-bold' style={{ fontSize: '20px' }}>Buyer Details</h5>
              <div className='divider'></div>

              <Label className="font-weight-bold mt-3">Name</Label>
              <FormGroup>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={form.name}
                  onChange={handleChange}
                />
              </FormGroup>

              <Label className="font-weight-bold mt-3">Email</Label>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={form.email}
                  onChange={handleChange}
                />
              </FormGroup>

              <Label className="font-weight-bold mt-3">Phone Number</Label>
              <FormGroup>
                <Input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={form.phoneNumber}
                  onChange={handleChange}
                />
              </FormGroup>

              <Label className="font-weight-bold mt-3">Notes To Shop By Buyer</Label>
              <FormGroup>
                <Input
                  type="textarea"
                  rows="7"
                  name="notesToVendor"
                  id="notesToVendor"
                  className="h-100"
                  value={form.notesToVendor}
                  onChange={handleChange}
                />
              </FormGroup>

              <Label className="font-weight-bold mt-3">Shipping Address</Label>
              <FormGroup>
                <Input
                  type="textarea"
                  rows="5"
                  name="address"
                  id="address"
                  className="h-100"
                  value={form.address}
                  onChange={handleChange}
                />
              </FormGroup>

              <Label className="font-weight-bold mt-3">Shipping Option</Label>
              <FormGroup>
                <Input
                  type="select"
                  name="shippingOption"
                  id="shippingOption"
                  value={form.shippingOption}
                  onChange={handleChange}
                >
                  <option value="self" key="self">Self-pickup</option>
                  <option value="postage" key="postage">Postage</option>
                </Input>
              </FormGroup>

              <Label className="font-weight-bold mt-3">Total Price</Label>
              <FormGroup>
                <Input
                  type="text"
                  name="totalPrice"
                  id="totalPrice"
                  value={form.totalPrice.toFixed(2)}
                  readOnly
                />
              </FormGroup>

              <Label className="font-weight-bold mt-3">Wholesale Price</Label>
              <FormGroup>
                <Input
                  type="text"
                  name="wholesalePrice"
                  id="wholesalePrice"
                  value={form.wholesalePrice.toFixed(2)}
                  readOnly
                />
              </FormGroup>

              <Label className="font-weight-bold mt-3">Service Fee</Label>
              <FormGroup>
                <Input
                  type="text"
                  name="serviceFee"
                  id="serviceFee"
                  value={form.serviceFee.toFixed(2)}
                  readOnly
                />
              </FormGroup>

              <Label className="font-weight-bold mt-3">Shipping Fee</Label>
              <FormGroup>
                <Input
                  type="text"
                  name="shippingFee"
                  id="shippingFee"
                  value={form.shippingFee.toFixed(2)}
                  readOnly
                />
              </FormGroup>

              <div className='mt-5 pt-5'>
                <Row>
                  <Col>
                    <Button
                      className="primary-btn"
                      style={{ width: '100%' }}
                      onClick={handleSave}
                      disabled={submitInProgress}
                    >
                      <SpinnerInButton loading={submitInProgress} text="Update details" />
                      {submitInProgress && (<span className="ml-3">Updating</span>)}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={handleCancel}
                      style={{ width: '100%' }}
                      className="cancel-btn"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </div>
            </Container>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}