import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Header from '../header/Header';
import { Alert, Container } from 'reactstrap';

interface Props extends RouteComponentProps<{}> {
}

export const VerifyEmailPage: React.FC<Props> = (props) => {

  return (
    <>
      <Header />
      <Container className='mt-5 pt-5'>
        <div>
          <Alert color='primary'>
            We have sent you a verification link to your email.<br/>
            Please click on the link to verify your email.
          </Alert>
        </div>
      </Container>
    </>
  );
};
