import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import { OrderModel } from '../../models/Order';
import { AdminOrderUpdateModal } from './AdminOrderUpdateModal';

type Props = {
  order: OrderModel;
  index: number;
}

export const AdminOrderListTable = ({ order, index }: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return(
    <>
      <tr>
        <td className='text-center'>{index + 1}</td>
        <td className="text-uppercase font-weight-bold">{order.name}</td>
        <td>{order.phoneNumber}</td>
        <td>{order.email}</td>
        <td>
          <Link to={`/products/${order.productId}`} className='link-text-secondary' rel="noopener noreferrer" target="_blank">
            {order.title}
          </Link>
        </td>
        <td>
          <Link to={`/shops/${order.vendorId}`} className='link-text-secondary' rel="noopener noreferrer" target="_blank">
            {order.vendorName}
          </Link>
        </td>
        <td className='text-center'>
        {(!order.paymentStatus || (order.paymentStatus === "pending")) && (<Badge color='warning'>PENDING</Badge>)}
          {(order.paymentStatus === "confirmation") && (<Badge color='warning'>CONFIRMATION</Badge>)}
          {(order.paymentStatus === "successful") && (<Badge color='success'>COMPLETED</Badge>)}
          {(order.paymentStatus === "failed") && (<Badge color='danger'>FAILED</Badge>)}
        </td>
        <td className='text-center'>
          {(!order.shippingStatus || (order.shippingStatus === "pending")) && (<Badge color='warning'>PENDING</Badge>)}
          {(order.shippingStatus === "shipped") && (<Badge color='primary'>SHIPPED</Badge>)}
          {(order.shippingStatus === "delivered") && (<Badge color='primary'>DELIVERED</Badge>)}
          {(order.shippingStatus === "unsuccessful") && (<Badge color='danger'>DELIVERY ATTEMPTED</Badge>)}
        </td>
        <td className='text-center'>{order.wholesalePrice.toFixed(2)}</td>
        <td className='text-center'>{order.quantity}</td>
        <td className='text-center'>{order.totalPrice.toFixed(2)}</td>
        <td className="text-center">
          <Button
            className="white-btn"
            style={{ borderRadius: "10px" }}
            onClick={toggleModal}
          >
            View
          </Button>
        </td>
      </tr>
      <AdminOrderUpdateModal 
        open={openModal}
        toggle={toggleModal}
        order={order}
      />
    </>
  )
}