import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Button, Modal, ModalHeader, ModalBody, Label, FormGroup, Input, FormText } from 'reactstrap';
import ErrorMessageList from '../shared/ErrorMessageList';
import SpinnerInButton from '../shared/SpinnerInButton';
import GraphqlClient from '../../utils/GraphqlClient';
import S3Uploader, { S3Object } from '../../utils/S3Uploader';
import { DateTimeFormatter } from '../../utils/DateTimeFormatter';
import StripeRestClient from '../../utils/StripeRestClient';
import { UserContext } from '../../UserContext';
import { ProductModel } from '../../models/Product';
import Calculations from '../../utils/Calculations';

interface Props {
  open: boolean;
  toggle: () => void;
  vendorId: string;
  product: ProductModel;
}

export const EditProductModal: React.FC<Props> = (props) => {
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [fileChosen, setFileChosen] = useState(false);
  const [fileChosen2, setFileChosen2] = useState(false);
  const [fileChosen3, setFileChosen3] = useState(false);
  const [fileChosen4, setFileChosen4] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState({
    vendorId: props.vendorId,
    title: props.product.title,
    brandName: props.product.brandName,
    description: props.product.description,
    weight: props.product.weight,
    retailPrice: props.product.retailPrice,
    wholesalePrice: props.product.wholesalePrice,
    pricePerUnit: props.product.pricePerUnit,
    shippingFee: props.product.shippingFee,
    unitPerPackaging: props.product.unitPerPackaging,
    minQuantity: props.product.minQuantity,
    category: props.product.category,
    notes: props.product.notes
  });
  const [photoForm, setPhotoForm] = useState({
    productPhoto: {} as S3Object,
  });
  const [photoForm2, setPhotoForm2] = useState({
    productPhoto2: {} as S3Object,
  });
  const [photoForm3, setPhotoForm3] = useState({
    productPhoto3: {} as S3Object,
  });
  const [photoForm4, setPhotoForm4] = useState({
    productPhoto4: {} as S3Object,
  });

  const handleChange = event => {
    const { name, value, type } = event.target;
    const newForm = {
      ...form,
      [name]: type === 'number' ? parseFloat(value) : value
    };
    setForm(newForm);
  };

  const handleFileChange = event => {
    const productPhoto = event.target.files[0];
    setPhotoForm({
      ...photoForm,
      productPhoto
    });
    setFileChosen(true);
  };

  const handleFileChange2 = event => {
    const productPhoto2 = event.target.files[0];
    setPhotoForm2({
      ...photoForm2,
      productPhoto2
    });
    setFileChosen2(true);
  };

  const handleFileChange3 = event => {
    const productPhoto3 = event.target.files[0];
    setPhotoForm3({
      ...photoForm3,
      productPhoto3
    });
    setFileChosen3(true);
  };

  const handleFileChange4 = event => {
    const productPhoto4 = event.target.files[0];
    setPhotoForm4({
      ...photoForm4,
      productPhoto4
    });
    setFileChosen4(true);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setSubmitInProgress(true);
    try {
      const { productPhoto } = photoForm;
      const { productPhoto2 } = photoForm2;
      const { productPhoto3 } = photoForm3;
      const { productPhoto4 } = photoForm4;
      const { vendorId, title, brandName, description, weight, retailPrice, wholesalePrice, pricePerUnit, shippingFee, unitPerPackaging, minQuantity, category, notes } = form;
      if (fileChosen && !fileChosen2 && !fileChosen3 && !fileChosen4) {
        const pictureS3Object = await S3Uploader.reUploadProductImage(productPhoto, title, DateTimeFormatter.formatCreatedDateTime(new Date().toISOString()));
        const productRequestParam = {
          productId: props.product.stripeProductId,
          name: title,
          description: description
        };
        const stripeProduct = await StripeRestClient.updateProduct(props.product.stripeProductId, productRequestParam);
        const priceRequestParam = {
          priceId: props.product.stripePriceId,
          wholesalePrice: Calculations.calculateTotalStripeNoServiceFee(wholesalePrice, shippingFee),
          productId: stripeProduct.id
        };
        const stripePrice = await StripeRestClient.updatePrice(props.product.stripePriceId, priceRequestParam);
        const input = {
          id: props.product.id,
          vendorUserId: userData?.id,
          vendorId,
          stripeProductId: stripeProduct.id,
          stripePriceId: stripePrice.id,
          productPhoto: pictureS3Object,
          title,
          brandName,
          description,
          weight,
          retailPrice,
          wholesalePrice,
          pricePerUnit,
          shippingFee,
          unitPerPackaging,
          minQuantity,
          category,
          notes
        };
        const result = await GraphqlClient.updateProduct(input);
        const { updateProduct } = result.data;
        props.toggle();
        history.push('/');
        history.push(`/products/${updateProduct.id}`);
      } else if (fileChosen && fileChosen2 && !fileChosen3 && !fileChosen4) {
        const pictureS3Object = await S3Uploader.reUploadProductImage(productPhoto, title, DateTimeFormatter.formatCreatedDateTime(new Date().toISOString()));
        const titlePhoto2 = title + "2";
        const pictureS3Object2 = await S3Uploader.reUploadProductImage(productPhoto2, titlePhoto2, DateTimeFormatter.formatCreatedDateTime(new Date().toISOString()));
        const productRequestParam = {
          productId: props.product.stripeProductId,
          name: title,
          description: description
        };
        const stripeProduct = await StripeRestClient.updateProduct(props.product.stripeProductId, productRequestParam);
        const priceRequestParam = {
          priceId: props.product.stripePriceId,
          wholesalePrice: Calculations.calculateTotalStripeNoServiceFee(wholesalePrice, shippingFee),
          productId: stripeProduct.id
        };
        const stripePrice = await StripeRestClient.updatePrice(props.product.stripePriceId, priceRequestParam);
        const input = {
          id: props.product.id,
          vendorUserId: userData?.id,
          vendorId,
          stripeProductId: stripeProduct.id,
          stripePriceId: stripePrice.id,
          productPhoto: pictureS3Object,
          productPhoto2: pictureS3Object2,
          title,
          brandName,
          description,
          weight,
          retailPrice,
          wholesalePrice,
          pricePerUnit,
          shippingFee,
          unitPerPackaging,
          minQuantity,
          category,
          notes
        };
        const result = await GraphqlClient.updateProduct(input);
        const { updateProduct } = result.data;
        props.toggle();
        history.push('/');
        history.push(`/products/${updateProduct.id}`);
      } else if (fileChosen && fileChosen2 && fileChosen3 && !fileChosen4) {
        const pictureS3Object = await S3Uploader.reUploadProductImage(productPhoto, title, DateTimeFormatter.formatCreatedDateTime(new Date().toISOString()));
        const titlePhoto2 = title + "2";
        const pictureS3Object2 = await S3Uploader.reUploadProductImage(productPhoto2, titlePhoto2, DateTimeFormatter.formatCreatedDateTime(new Date().toISOString()));
        const titlePhoto3 = title + "3";
        const pictureS3Object3 = await S3Uploader.reUploadProductImage(productPhoto3, titlePhoto3, DateTimeFormatter.formatCreatedDateTime(new Date().toISOString()));
        const productRequestParam = {
          productId: props.product.stripeProductId,
          name: title,
          description: description
        };
        const stripeProduct = await StripeRestClient.updateProduct(props.product.stripeProductId, productRequestParam);
        const priceRequestParam = {
          priceId: props.product.stripePriceId,
          wholesalePrice: Calculations.calculateTotalStripeNoServiceFee(wholesalePrice, shippingFee),
          productId: stripeProduct.id
        };
        const stripePrice = await StripeRestClient.updatePrice(props.product.stripePriceId, priceRequestParam);
        const input = {
          id: props.product.id,
          vendorUserId: userData?.id,
          vendorId,
          stripeProductId: stripeProduct.id,
          stripePriceId: stripePrice.id,
          productPhoto: pictureS3Object,
          productPhoto2: pictureS3Object2,
          productPhoto3: pictureS3Object3,
          title,
          brandName,
          description,
          weight,
          retailPrice,
          wholesalePrice,
          pricePerUnit,
          shippingFee,
          unitPerPackaging,
          minQuantity,
          category,
          notes
        };
        const result = await GraphqlClient.updateProduct(input);
        const { updateProduct } = result.data;
        props.toggle();
        history.push('/');
        history.push(`/products/${updateProduct.id}`);
      } else if (fileChosen && fileChosen2 && fileChosen3 && fileChosen4) {
        const pictureS3Object = await S3Uploader.reUploadProductImage(productPhoto, title, DateTimeFormatter.formatCreatedDateTime(new Date().toISOString()));
        const titlePhoto2 = title + "2";
        const pictureS3Object2 = await S3Uploader.reUploadProductImage(productPhoto2, titlePhoto2, DateTimeFormatter.formatCreatedDateTime(new Date().toISOString()));
        const titlePhoto3 = title + "3";
        const pictureS3Object3 = await S3Uploader.reUploadProductImage(productPhoto3, titlePhoto3, DateTimeFormatter.formatCreatedDateTime(new Date().toISOString()));
        const titlePhoto4 = title + "4";
        const pictureS3Object4 = await S3Uploader.reUploadProductImage(productPhoto4, titlePhoto4, DateTimeFormatter.formatCreatedDateTime(new Date().toISOString()));
        const productRequestParam = {
          productId: props.product.stripeProductId,
          name: title,
          description: description
        };
        const stripeProduct = await StripeRestClient.updateProduct(props.product.stripeProductId, productRequestParam);
        const priceRequestParam = {
          priceId: props.product.stripePriceId,
          wholesalePrice: Calculations.calculateTotalStripeNoServiceFee(wholesalePrice, shippingFee),
          productId: stripeProduct.id
        };
        const stripePrice = await StripeRestClient.updatePrice(props.product.stripePriceId, priceRequestParam);
        const input = {
          id: props.product.id,
          vendorUserId: userData?.id,
          vendorId,
          stripeProductId: stripeProduct.id,
          stripePriceId: stripePrice.id,
          productPhoto: pictureS3Object,
          productPhoto2: pictureS3Object2,
          productPhoto3: pictureS3Object3,
          productPhoto4: pictureS3Object4,
          title,
          brandName,
          description,
          weight,
          retailPrice,
          wholesalePrice,
          pricePerUnit,
          shippingFee,
          unitPerPackaging,
          minQuantity,
          category,
          notes
        };
        const result = await GraphqlClient.updateProduct(input);
        const { updateProduct } = result.data;
        props.toggle();
        history.push('/');
        history.push(`/products/${updateProduct.id}`);
      } else {
        const productRequestParam = {
          productId: props.product.stripeProductId,
          name: title,
          description: description
        };
        const stripeProduct = await StripeRestClient.updateProduct(props.product.stripeProductId, productRequestParam);
        const priceRequestParam = {
          priceId: props.product.stripePriceId,
          wholesalePrice: Calculations.calculateTotalStripeNoServiceFee(wholesalePrice, shippingFee).toFixed(2),
          productId: stripeProduct.id
        };
        const stripePrice = await StripeRestClient.updatePrice(props.product.stripePriceId, priceRequestParam);
        const input = {
          id: props.product.id,
          vendorUserId: userData?.id,
          vendorId,
          stripeProductId: stripeProduct.id,
          stripePriceId: stripePrice.id,
          title,
          brandName,
          description,
          weight,
          retailPrice,
          wholesalePrice,
          pricePerUnit,
          shippingFee,
          unitPerPackaging,
          minQuantity,
          category,
          notes
        };
        const result = await GraphqlClient.updateProduct(input);
        const { updateProduct } = result.data;
        props.toggle();
        history.push('/');
        history.push(`/products/${updateProduct.id}`);
      }
    } catch (err) {
      setSubmitInProgress(false);
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  };

  const title = {
    fontSize: '18px',
  };

  return (
    <>
      {props.open && (
        <Modal isOpen={props.open} toggle={props.toggle} size="lg" zIndex={1250}>
          <ModalHeader toggle={props.toggle}>
            <span style={title} className="font-weight-bold">Update {props.product.title} Details</span>
          </ModalHeader>
          <ModalBody  className="pb-5 mb-5">
            <Container className="pb-5">
              <ErrorMessageList errors={errors} />
              <Label className="font-weight-bold">Product name
                <span className="danger"> *</span>
              </Label>
              <FormGroup>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  value={form.title}
                  onChange={handleChange}
                />
              </FormGroup>
              <Label className="font-weight-bold mt-3">Upload new product's photo</Label>
              <FormGroup>
                <Input
                  type="file"
                  name="productPhoto"
                  id="productPhoto"
                  onChange={handleFileChange}
                >
                </Input>
                <FormText>
                  We only support jpg and png file only.
                </FormText>
              </FormGroup>
              <Label className="font-weight-bold mt-3">Upload new second product's photo</Label>
              <FormGroup>
                <Input
                  type="file"
                  name="productPhoto2"
                  id="productPhoto2"
                  onChange={handleFileChange2}
                >
                </Input>
                <FormText>
                  We only support jpg and png file only.
                </FormText>
              </FormGroup>
              <Label className="font-weight-bold mt-3">Upload new third product's photo</Label>
              <FormGroup>
                <Input
                  type="file"
                  name="productPhoto3"
                  id="productPhoto3"
                  onChange={handleFileChange3}
                >
                </Input>
                <FormText>
                  We only support jpg and png file only.
                </FormText>
              </FormGroup>
              <Label className="font-weight-bold mt-3">Upload new fourth product's photo</Label>
              <FormGroup>
                <Input
                  type="file"
                  name="productPhoto4"
                  id="productPhoto4"
                  onChange={handleFileChange4}
                >
                </Input>
                <FormText>
                  We only support jpg and png file only.
                </FormText>
              </FormGroup>
              <Label className="font-weight-bold mt-3">Retail Price (RM)
                <span className="danger"> *</span>
              </Label>
              <FormGroup>
                <Input
                  type="number"
                  name="retailPrice"
                  id="retailPrice"
                  step="0.01"
                  value={form.retailPrice}
                  onChange={handleChange}
                />
              </FormGroup>
              <Label className="font-weight-bold mt-3">Wholesale Price (RM)
                <span className="danger"> *</span>
              </Label>
              <FormGroup>
                <Input
                  type="number"
                  name="wholesalePrice"
                  id="wholesalePrice"
                  step="0.01"
                  value={form.wholesalePrice}
                  onChange={handleChange}
                />
              </FormGroup>
              <Label className="font-weight-bold mt-3">Price Per Unit (RM)
                <span className="danger"> *</span>
              </Label>
              <FormGroup>
                <Input
                  type="number"
                  name="pricePerUnit"
                  id="pricePerUnit"
                  step="0.01"
                  value={form.pricePerUnit}
                  onChange={handleChange}
                />
              </FormGroup>
              {form.weight && (
                <>
                  <Label className="font-weight-bold mt-3">Weight per product (kg)</Label>
                  <FormGroup>
                    <Input
                      type="number"
                      name="weight"
                      id="weight"
                      step="0.01"
                      value={form.weight}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </>
              )}
              <Label className="font-weight-bold mt-3">Shipping Fee (RM)
                <span className="danger"> *</span>
              </Label>
              <FormGroup>
                <Input
                  type="number"
                  name="shippingFee"
                  id="shippingFee"
                  step="0.01"
                  value={form.shippingFee}
                  onChange={handleChange}
                />
              </FormGroup>
              <Label className="font-weight-bold mt-3">Category</Label>
              <FormGroup>
                <Input
                  type="select"
                  name="category"
                  id="category"
                  value={form.category}
                  onChange={handleChange}
                >
                  <option></option>
                  <option disabled className='font-weight-bold'>1. Health & Beauty</option>
                  <option key="personalCare" value="personalCare">Personal care</option>
                  <option key="skinCare" value="skinCare">Skin care</option>
                  <option key="makeUp" value="makeUp">Make up</option>
                  <option key="perfume" value="perfume">Perfume</option>

                  <option disabled className='font-weight-bold pt-3'>2. Fashion</option>
                  <option key="men" value="men">Men's</option>
                  <option key="women" value="women">Women's</option>

                  <option disabled className='font-weight-bold'>3. Home & Living</option>
                  <option key="householdAppliances" value="householdAppliances">Household appliances</option>
                  <option key="kitchenAndDining" value="kitchenAndDining">Kitchen and dining</option>

                  <option disabled className='font-weight-bold'>4. Electronics</option>
                  <option key="electronics" value="electronics">Electronics</option>
                </Input>
              </FormGroup>
              <Label className="font-weight-bold mt-3">Product description</Label>
              <FormGroup>
                <Input
                  type="textarea"
                  rows="7"
                  name="description"
                  id="description"
                  className="h-100"
                  value={form.description}
                  onChange={handleChange}
                />
              </FormGroup>
              <Label className="font-weight-bold mt-3">Unit per 1 packaging</Label>
              <FormGroup>
                <Input
                  type="number"
                  name="unitPerPackaging"
                  id="unitPerPackaging"
                  value={form.unitPerPackaging}
                  onChange={handleChange}
                />
              </FormGroup>
              <Label className="font-weight-bold mt-3">Minimum order per 1 packaging</Label>
              <FormGroup>
                <Input
                  type="number"
                  name="minQuantity"
                  id="minQuantity"
                  value={form.minQuantity}
                  onChange={handleChange}
                />
              </FormGroup>

              <div className="mt-5 text-right">
                <Button
                  className="primary-btn"
                  style={{ fontSize: '18px' }}
                  onClick={handleSubmit}
                >
                  <SpinnerInButton loading={submitInProgress} text="Update my product" />
                  {submitInProgress && (<span className="ml-3">Updating</span>)}
                </Button>
              </div>
            </Container>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}