import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Button, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import ErrorMessageList from '../shared/ErrorMessageList';
import SpinnerInButton from '../shared/SpinnerInButton';
import GraphqlClient from '../../utils/GraphqlClient';

interface Props {
  open: boolean;
  toggle: () => void;
  productId: string;
  title: string;
  vendorId: string;
}

export const ArchiveProductModal: React.FC<Props> = (props) => {
  const history = useHistory();
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleSubmit = async event => {
    event.preventDefault();
    setSubmitInProgress(true);
    try {
      const input = {
        id: props.productId,
        active: false
      };
      await GraphqlClient.updateProduct(input);
      props.toggle();
      history.push('/');
      history.push(`/shops/${props.vendorId}`);
    } catch (err) {
      setSubmitInProgress(false);
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  };

  const handleCancel = () => {
    props.toggle();
  };

  const title = {
    fontSize: '18px',
  };

  return (
    <>
      {props.open && (
        <Modal isOpen={props.open} toggle={props.toggle} size="md" zIndex={1250}>
          <ModalHeader toggle={props.toggle}>
            <span style={title} className="font-weight-bold">Are you sure?</span>
          </ModalHeader>
          <ModalBody  className="pb-5 mb-5">
            <Container className="pb-5 pt-3">
              <ErrorMessageList errors={errors} />
              <h5 className='grey-color'>
                You're about to archive the product below.
              </h5>
              <h5 className='font-weight-bold mt-3'>
                {props.title}
              </h5>

              <Row className="mt-5 pt-5">
                <Col>
                  <Button
                    className="primary-btn"
                    style={{ fontSize: '18px' }}
                    onClick={handleSubmit}
                  >
                    <SpinnerInButton loading={submitInProgress} text="Archive this product" />
                    {submitInProgress && (<span className="ml-3">Archiving</span>)}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={handleCancel}
                    style={{ width: '100%' }}
                    className="cancel-btn"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}