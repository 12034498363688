import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem
} from 'reactstrap';
import Logo from '../../assets/bababom-logo.png';

const Header = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return(
    <>
      <Navbar className="fixed-navbar pt-3 pb-3" expand="md">
        <Link to="/" className="text-decoration-none" style={{ width: '230px' }}>
          <img src={Logo} alt="Ringgit Lab Logo" className="w-100" />
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={open} navbar>
          <Nav className="mt-0 ml-auto" navbar>
            <NavItem className="toggle-space">
              <Link to="/vendor-signup" className="nav-item" onClick={toggle}>Setup A Shop</Link>
            </NavItem>
            <NavItem className="toggle-space">
              <Link to="/login" className="nav-item" onClick={toggle}>Log In</Link>
            </NavItem>
            <NavItem className="toggle-space">
              <Link to="/signup" className="header-pill">Sign Up For Free</Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  )
}

export default Header;