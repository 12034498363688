import React, { useEffect, useState, useContext } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import GraphqlClient from '../../utils/GraphqlClient';
import { modelFactory } from '../../models/ModelFactory';
import { UserContext } from '../../UserContext';
import AuthNavbar from '../header/AuthNavbar';
import { OrderModel } from '../../models/Order';
import LoadingSpinner from '../shared/LoadingSpinner';
import { AdminOrderListTable } from './AdminOrderListTable';

const AdminOrderPage = () => {
  const { userData } = useContext(UserContext);
  const [errors, setErrors] = useState([]);
  const [allOrders, setAllOrders] = useState<OrderModel[]>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      const filter = {
        active: { ne: false }
      };
      (async () => {
        const results = await Promise.all([
          GraphqlClient.listOrders(filter)
        ]);
        const allOrders = results[0].map(i =>
          modelFactory<OrderModel>(i, OrderModel)
        );
        setAllOrders(allOrders);
      })();
    } catch (err) {
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  }, [userData, errors]);

  if (!allOrders) {
    return <LoadingSpinner />
  }

  return(
    <>
      <AuthNavbar />
      <div className='pt-5 mt-5 container-fluid'>
        <div className='pt-5 mt-5'>
          <h2 style={{ color: '#000', fontFamily: '"Montserrat", sans-serif', fontSize: '32px', fontWeight: 600 }}>
            Orders List
          </h2>
          <div className='divider'></div>
          <Row className="mt-5">
            <Col sm="12">
              <Table striped bordered responsive>
                <thead>
                  <tr className="text-center">
                    <th>#</th>
                    <th className='text-left'>User</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Product</th>
                    <th>Shop Name</th>
                    <th>Payment Status</th>
                    <th>Shipping Status</th>
                    <th>Wholesale Price (RM)</th>
                    <th>Quantity</th>
                    <th>Total Price (RM)</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {allOrders && allOrders.map((order, index)=> (
                    <AdminOrderListTable
                      order={order}
                      key={order.id}
                      index={index}
                    />
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default AdminOrderPage;