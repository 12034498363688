import { API } from 'aws-amplify';

const apiName = "kaijuustripe";
const customerRequestPath = "/customers";
const productRequestPath = "/products";
const priceRequestPath = "/prices";
const paymentLinkRequestPath = "/create-payment-link";

class StripeRestClient {

  async createCustomer(requestParams) {
    return this.requestToAmplifyAPI(customerRequestPath, requestParams);
  }

  async updateCustomer(customerId: string, requestParams) {
    const path = `${customerRequestPath}/${customerId}`;
    return this.requestToAmplifyAPI(path, requestParams);
  }

  async createProduct(requestParams) {
    return this.requestToAmplifyAPI(productRequestPath, requestParams);
  }

  async updateProduct(productId: string, requestParams) {
    const path = `${productRequestPath}/${productId}`;
    return this.requestToAmplifyAPI(path, requestParams);
  }

  async createPrice(requestParams) {
    return this.requestToAmplifyAPI(priceRequestPath, requestParams);
  }

  async updatePrice(priceId: string, requestParams) {
    const path = `${priceRequestPath}/${priceId}`;
    return this.requestToAmplifyAPI(path, requestParams);
  }

  // async getPrice(priceId: string, requestParams) {
  //   const path = `${priceRequestPath}/${priceId}`;
  //   return this.requestToAmplifyAPI(path, requestParams);
  // }

  // async getPrice(priceId: string) {
  //   const path = `${priceRequestPath}/${priceId}`;
  //   return API.get(apiName, path, {
  //     queryStringParameters: {
  //       priceId: priceId
  //     }
  //   });
  // }

  async createPaymentLink(requestParams) {
    return this.requestToAmplifyAPI(paymentLinkRequestPath, requestParams);
  }

  // async createPortalSession(requestParams) {
  //   return this.requestToAmplifyAPI(sessionRequestPath, requestParams);
  // }

  private async requestToAmplifyAPI(requestPath: string, requestParams) {
    const init = {
      body: requestParams,
      headers: { 
        "Content-Type": "application/json"
      },
    };
    return API.post(apiName, requestPath, init);
  }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new StripeRestClient();