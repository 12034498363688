import React, { useContext, useEffect, useState } from 'react';
import GraphqlClient from '../../utils/GraphqlClient';
import { modelFactory } from '../../models/ModelFactory';
import { Table, Row, Col, Container, Button } from 'reactstrap';
import LoadingSpinner from '../shared/LoadingSpinner';
import { VendorModel } from '../../models/Vendor';
import { VendorListTable } from './VendorListTable';
import AuthNavbar from '../header/AuthNavbar';
import { UserContext } from '../../UserContext';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const AllVendorsPage = () => {
  const { userData } = useContext(UserContext);
  const [allVendors, setAllVendors] = useState<VendorModel[]>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const listVendors = async () => {
      const results = await Promise.all([
        GraphqlClient.listVendors(null)
      ]);
      const allVendors = results[0].map(i => modelFactory<VendorModel>(i, VendorModel))
      .sort((a, b) => {
        if (b.name > a.name) return -1;
        if (b.name < a.name) return 1;
        return 0;
      });
      setAllVendors(allVendors);
    };
    listVendors();
  }, []);

  if (!allVendors) {
    return <LoadingSpinner />
  }

  return(
    <>
      <AuthNavbar />
      <Container className='pt-5 mt-5 pb-5'>
      <h2 style={{ color: '#000', fontFamily: '"Montserrat", sans-serif', fontSize: '40px', fontWeight: 600 }} className='pt-5 mt-5'>
        Shop List
      </h2>
        <div className='divider pb-3'></div>
        {userData && (userData.userType === "admin") && (
          <Link to="/create-shop">
            <Button
              className="primary-btn"
              style={{ width: '200px' }}
            >
              <span className="mr-3"><FontAwesomeIcon icon={faPlusCircle} /></span>
              Add new shop
            </Button>
          </Link>
        )}
        <Row className="mt-5">
          <Col sm="12">
            <Table striped bordered responsive>
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th className='text-left'>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th>Shipping</th>
                  <th>Vendor Page</th>
                </tr>
              </thead>
              <tbody>
                {allVendors && allVendors.map((vendor, index) => (
                  <VendorListTable 
                    key={vendor.id}
                    vendor={vendor}
                    index={index}
                  />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AllVendorsPage;