/* eslint-disable import/no-anonymous-default-export */
import { Auth } from 'aws-amplify';

class AuthClient {

  currentAuthenticatedUser(option? :any) {
    return Auth.currentAuthenticatedUser(option);
  }

  async currentAuthenticatedUserIfPresent() {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (err) {
      // ignore
    }
    return user;
  }

  signUp(params, ...restOfAttrs) {
    return Auth.signUp(params, ...restOfAttrs);
  }

  signIn(username, password) {
    return Auth.signIn(username, password);
  }

  forgotPassword(username) {
    return Auth.forgotPassword(username);
  }

  signOut() {
    return Auth.signOut();
  }

  resendSignUp(username) {
    return Auth.resendSignUp(username);
  }

  confirmSignUp(username, code) {
    return Auth.confirmSignUp(username, code);
  }

  forgotPasswordSubmit(username, code, password) {
    return Auth.forgotPasswordSubmit(username, code, password);
  }

  async updateUserAttributes(attributes) {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.updateUserAttributes(user, attributes);
  }

  async changePassword(oldPassword, newPassword) {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.changePassword(user, oldPassword, newPassword);
  }

  async verifyEmail(code) {
    return Auth.verifyCurrentUserAttributeSubmit("email", code);
  }

  async verifyUserPhoneNumber(phoneNumber) {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "phone_number": phoneNumber,
    });
    return Auth.verifyUserAttribute(user, "phone_number");
  }

  confirmPhoneNumber(code) {
    return Auth.verifyCurrentUserAttributeSubmit("phone_number", code);
  }

  async currentUserPhoneNumber() {
    const user = await Auth.currentAuthenticatedUser();
    const attrs = await Auth.userAttributes(user);
    const phoneNumberObject = attrs.find(attr => attr.getName() === "phone_number");
    return phoneNumberObject ? phoneNumberObject.getValue() : "";
  }

}

export default new AuthClient();