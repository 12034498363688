import { S3Object } from "../API";

export abstract class Product {
  id: string;
  vendorUserId: string;
  vendorId: string;
  stripeProductId: string;
  stripePriceId: string;
  productPhoto: S3Object;
  productPhoto2: S3Object;
  productPhoto3: S3Object;
  productPhoto4: S3Object;
  title: string;
  brandName: string;
  description: string;
  weight: number;
  retailPrice: number;
  wholesalePrice: number;
  pricePerUnit: number;
  shippingFee: number;
  unitPerPackaging: number;
  minQuantity: number;
  category: string;
  notes: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export class ProductModel extends Product {
  json: any;

  constructor(json) {
    super();
    this.json = json;
  }
}