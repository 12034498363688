import React, { useState } from 'react';
import {
  Alert
} from 'reactstrap';

function ErrorMessage(props) {
  const [visible, setVisible] = useState(true);
  if (!props.error) return null;
  return (
    <Alert
      color="danger"
      isOpen={visible}
      toggle={() => setVisible(false)}
    >
      {props.error}
    </Alert>
  );
}

export default ErrorMessage;