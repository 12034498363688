import React, { useState, useContext } from 'react';
import { Link, withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Badge,
} from 'reactstrap';
import AuthClient from '../../utils/AuthClient';
import { UserContext } from '../../UserContext';
import Logo from '../../assets/bababom-logo.png';

interface Props extends RouteComponentProps<{}> {
}

const AuthNavBar: React.FC<Props> = (props) => {
  let history = useHistory();
  const { userData } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    await AuthClient.signOut();
    history.push("/");
    history.go(0);
  };

  return (
    <Navbar className="fixed-navbar pt-3 pb-3" expand="md">
      <Link to="/" className="text-decoration-none" style={{ width: '230px' }}>
        <img src={Logo} alt="Ringgit Lab Logo" className="w-100" />
      </Link>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          {(userData.userType === "admin") && (
            <>
              <NavItem>
                <Link to="/all-orders" className="nav-item">
                  Order List
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/shops" className="nav-item">
                  Shop List
                </Link>
              </NavItem>
            </>
          )}
          {((userData.userType === "vendor") && !userData.vendorId) && (
            <NavItem>
              <Link to="/create-shop" className="nav-item">
                <span className='mr-2'>
                  <Badge color='info'>!</Badge>
                </span>
                Setup Shop
              </Link>
            </NavItem>
          )}
          {((userData.userType === "vendor") && userData.vendorId) && (
            <NavItem>
              <Link to={`/shops/${userData.vendorId}`} className="nav-item">
                My Shop
              </Link>
            </NavItem>
          )}
          {(userData.userType === "user") && (
            <NavItem>
              <Link to="/orders" className="nav-item">
                My Order
              </Link>
            </NavItem>
          )}
          <NavItem>
            <p className="nav-item">{userData.fullName}</p>
          </NavItem>
          <NavItem>
            <div onClick={handleLogout} className="nav-item" style={{ cursor: 'pointer' }}>Logout</div>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default withRouter(AuthNavBar);