import React from 'react';

class ErrorBoundary extends React.Component {

  componentDidCatch(error: any, errorInfo: any) {
    // eslint-disable-next-line no-console
    console.log(error, errorInfo);
  }

  render() {
    return this.props.children;
  }

}

export default ErrorBoundary;