import React from 'react';
import ErrorMessage from './ErrorMessage';

function ErrorMessageList(props) {
  if (!props.errors) return null;
  return (
    props.errors.map((error, index) => <ErrorMessage key={index} error={error} />)
  );
}

export default ErrorMessageList;