import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';

// suppress compile error: Observable<object> has not property, data
interface GraphqlResult {
  data?: any;
  errors?: [object];
  extensions?: {
    [key: string]: any;
  };
}
class GraphqlClient {

  async getUser(id) {
    return API.graphql(graphqlOperation(queries.getUser, { id: id })) as Promise<GraphqlResult>;
  }

  async registerUser(input) {
    return API.graphql(graphqlOperation(mutations.registerUser, { input })) as Promise<GraphqlResult>;
  }

  async updateUser(input) {
    return API.graphql(graphqlOperation(mutations.updateUser, { input })) as Promise<GraphqlResult>;
  }

  async createVendor(input) {
    return API.graphql(graphqlOperation(mutations.createVendor, { input })) as Promise<GraphqlResult>;
  }

  async updateVendor(input) {
    return API.graphql(graphqlOperation(mutations.updateVendor, { input })) as Promise<GraphqlResult>;
  }

  async deleteVendor(input) {
    return API.graphql(graphqlOperation(mutations.deleteVendor, { input })) as Promise<GraphqlResult>;
  }

  async getVendor(id) {
    return API.graphql(graphqlOperation(queries.getVendor, { id: id })) as Promise<GraphqlResult>;
  }

  async listVendors(filter) {
    const vendors = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listVendors, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listVendors.items;
      vendors.push(...items);
      nextToken = result.data.listVendors.nextToken;
    } while (nextToken);
    return vendors;
  }

  async createProduct(input) {
    return API.graphql(graphqlOperation(mutations.createProduct, { input })) as Promise<GraphqlResult>;
  }

  async updateProduct(input) {
    return API.graphql(graphqlOperation(mutations.updateProduct, { input })) as Promise<GraphqlResult>;
  }

  async deleteProduct(input) {
    return API.graphql(graphqlOperation(mutations.deleteProduct, { input })) as Promise<GraphqlResult>;
  }

  async getProduct(id) {
    return API.graphql(graphqlOperation(queries.getProduct, { id: id })) as Promise<GraphqlResult>;
  }

  async listProducts(filter) {
    const products = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listProducts, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listProducts.items;
      products.push(...items);
      nextToken = result.data.listProducts.nextToken;
    } while (nextToken);
    return products;
  }

  async createOrder(input) {
    return API.graphql(graphqlOperation(mutations.createOrder, { input })) as Promise<GraphqlResult>;
  }

  async updateOrder(input) {
    return API.graphql(graphqlOperation(mutations.updateOrder, { input })) as Promise<GraphqlResult>;
  }

  async deleteOrder(input) {
    return API.graphql(graphqlOperation(mutations.deleteOrder, { input })) as Promise<GraphqlResult>;
  }

  async getOrder(id) {
    return API.graphql(graphqlOperation(queries.getOrder, { id: id })) as Promise<GraphqlResult>;
  }

  async listOrders(filter) {
    const orders = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listOrders, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listOrders.items;
      orders.push(...items);
      nextToken = result.data.listOrders.nextToken;
    } while (nextToken);
    return orders;
  }

  async createPayment(input) {
    return API.graphql(graphqlOperation(mutations.createPayment, { input })) as Promise<GraphqlResult>;
  }

  async updatePayment(input) {
    return API.graphql(graphqlOperation(mutations.updatePayment, { input })) as Promise<GraphqlResult>;
  }

  async deletePayment(input) {
    return API.graphql(graphqlOperation(mutations.deletePayment, { input })) as Promise<GraphqlResult>;
  }

  async getPayment(id) {
    return API.graphql(graphqlOperation(queries.getPayment, { id: id })) as Promise<GraphqlResult>;
  }

  async listPayments(filter) {
    const payments = [];
    let nextToken = null;
    do {
      const result = await API.graphql(graphqlOperation(queries.listPayments, { filter, nextToken })) as GraphqlResult;
      const items = result.data.listPayments.items;
      payments.push(...items);
      nextToken = result.data.listPayments.nextToken;
    } while (nextToken);
    return payments;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new GraphqlClient();