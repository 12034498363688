import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';

const LoadingSpinner = () => {
  return (
    <Row>
      <Col className="align-self-center">
        <div className="spinner">
          <Spinner style={{ width: "1rem", height: "1rem" }} type="grow" color="primary" />
          <Spinner style={{ width: "1rem", height: "1rem" }} type="grow" color="secondary" />
          <Spinner style={{ width: "1rem", height: "1rem" }} type="grow" color="success" />
          <Spinner style={{ width: "1rem", height: "1rem" }} type="grow" color="danger" />
          <Spinner style={{ width: "1rem", height: "1rem" }} type="grow" color="warning" />
          <Spinner style={{ width: "1rem", height: "1rem" }} type="grow" color="info" />
        </div>
        <div className="loading-spinner">
          <p>Loading ...</p>
        </div>
      </Col>
    </Row>
  );
};

export default LoadingSpinner;