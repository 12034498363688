/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://on2xxt5k5rcmvhgkokxz5qedzm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "kaijuustripe",
            "endpoint": "https://n1ate3fg2j.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:f92175cc-276c-4295-b96b-608aa76e70bb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_DOI1XYwrg",
    "aws_user_pools_web_client_id": "2o018h1mqs9e5upnonnmsk1tpd",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "kaiijuservice8df65810c9f14cf5933810fae3a37ddd213414-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
