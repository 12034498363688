/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const registerUser = /* GraphQL */ `
  mutation RegisterUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    registerUser(input: $input, condition: $condition) {
      id
      username
      email
      fullName
      phoneNumber
      userType
      vendorId
      stripeAccountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      fullName
      phoneNumber
      userType
      vendorId
      stripeAccountId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVendor = /* GraphQL */ `
  mutation CreateVendor(
    $input: CreateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    createVendor(input: $input, condition: $condition) {
      id
      vendorUserId
      name
      picture {
        bucket
        region
        key
        __typename
      }
      description
      email
      phoneNumber
      address
      shippingOption
      sourceLink
      active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVendor = /* GraphQL */ `
  mutation UpdateVendor(
    $input: UpdateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    updateVendor(input: $input, condition: $condition) {
      id
      vendorUserId
      name
      picture {
        bucket
        region
        key
        __typename
      }
      description
      email
      phoneNumber
      address
      shippingOption
      sourceLink
      active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVendor = /* GraphQL */ `
  mutation DeleteVendor(
    $input: DeleteVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    deleteVendor(input: $input, condition: $condition) {
      id
      vendorUserId
      name
      picture {
        bucket
        region
        key
        __typename
      }
      description
      email
      phoneNumber
      address
      shippingOption
      sourceLink
      active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      vendorUserId
      vendorId
      stripeProductId
      stripePriceId
      productPhoto {
        bucket
        region
        key
        __typename
      }
      productPhoto2 {
        bucket
        region
        key
        __typename
      }
      productPhoto3 {
        bucket
        region
        key
        __typename
      }
      productPhoto4 {
        bucket
        region
        key
        __typename
      }
      title
      brandName
      description
      weight
      retailPrice
      wholesalePrice
      pricePerUnit
      shippingFee
      unitPerPackaging
      minQuantity
      category
      notes
      active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      vendorUserId
      vendorId
      stripeProductId
      stripePriceId
      productPhoto {
        bucket
        region
        key
        __typename
      }
      productPhoto2 {
        bucket
        region
        key
        __typename
      }
      productPhoto3 {
        bucket
        region
        key
        __typename
      }
      productPhoto4 {
        bucket
        region
        key
        __typename
      }
      title
      brandName
      description
      weight
      retailPrice
      wholesalePrice
      pricePerUnit
      shippingFee
      unitPerPackaging
      minQuantity
      category
      notes
      active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      vendorUserId
      vendorId
      stripeProductId
      stripePriceId
      productPhoto {
        bucket
        region
        key
        __typename
      }
      productPhoto2 {
        bucket
        region
        key
        __typename
      }
      productPhoto3 {
        bucket
        region
        key
        __typename
      }
      productPhoto4 {
        bucket
        region
        key
        __typename
      }
      title
      brandName
      description
      weight
      retailPrice
      wholesalePrice
      pricePerUnit
      shippingFee
      unitPerPackaging
      minQuantity
      category
      notes
      active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      vendorId
      vendorName
      vendorEmail
      vendorPhoneNumber
      name
      userId
      email
      phoneNumber
      address
      shippingOption
      shippingStatus
      productId
      stripeProductId
      title
      notesToVendor
      retailPrice
      wholesalePrice
      quantity
      serviceFee
      shippingFee
      totalPrice
      paymentStatus
      active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      vendorId
      vendorName
      vendorEmail
      vendorPhoneNumber
      name
      userId
      email
      phoneNumber
      address
      shippingOption
      shippingStatus
      productId
      stripeProductId
      title
      notesToVendor
      retailPrice
      wholesalePrice
      quantity
      serviceFee
      shippingFee
      totalPrice
      paymentStatus
      active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      vendorId
      vendorName
      vendorEmail
      vendorPhoneNumber
      name
      userId
      email
      phoneNumber
      address
      shippingOption
      shippingStatus
      productId
      stripeProductId
      title
      notesToVendor
      retailPrice
      wholesalePrice
      quantity
      serviceFee
      shippingFee
      totalPrice
      paymentStatus
      active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      orderId
      productId
      productName
      vendorId
      vendorName
      clientId
      paymentStatus
      totalPrice
      serviceFee
      shippingFee
      orderPrice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      orderId
      productId
      productName
      vendorId
      vendorName
      clientId
      paymentStatus
      totalPrice
      serviceFee
      shippingFee
      orderPrice
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      orderId
      productId
      productName
      vendorId
      vendorName
      clientId
      paymentStatus
      totalPrice
      serviceFee
      shippingFee
      orderPrice
      createdAt
      updatedAt
      __typename
    }
  }
`;
