import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  Label,
} from 'reactstrap';
import ErrorMessageList from '../shared/ErrorMessageList';
import AuthClient from '../../utils/AuthClient';
import SpinnerInButton from '../shared/SpinnerInButton';
import Header from '../header/Header';

interface Props extends RouteComponentProps<{}> {
}

export const LoginPage: React.FC<Props> = (props) => {
  const [form, setForm] = useState({
    username: "",
    password: "",
    usernamePassword: "",
  });
  const [loginErrors, setLoginErrors] = useState([]);
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [valid, setValid] = useState(false);

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const valid = (() => {
      return !!form.username && !!form.password;
    })();
    setValid(valid);
    // eslint-disable-next-line
  }, [
    form.username,
    form.password,
  ]);

  const handleSignIn = async event => {
    event.preventDefault();
    setLoginInProgress(true);
    try {
      await AuthClient.signIn(form.username, form.password);
    } catch (err) {
      const error = err.message ? err.message : err;
      setLoginInProgress(false);
      setLoginErrors([...loginErrors, error])
    }
  }

  return (
    <>
      <Header />
      <Row className="pt-5 mt-5">
        <Col xl={4} lg={3} md={2} sm={12} xs={12}></Col>
        <Col xl={4} lg={6} md={8} sm={12} xs={12} className="align-self-center">
          <Container>
            <h1 className='pt-5 mt-5' style={{ color: '#000', fontFamily: '"Montserrat", sans-serif', fontSize: '36px', fontWeight: 600 }}>Login</h1>
            <div className='divider mb-3'></div>
            <ErrorMessageList errors={loginErrors} />
            <Form>
              <Label className='font-weight-bold mt-3'>Email address</Label>
              <FormGroup>
                <Input
                  autoFocus
                  type="email"
                  name="username"
                  value={form.username}
                  placeholder="Email address"
                  onChange={handleChange}
                />
              </FormGroup>

              
              <Label className='font-weight-bold mt-3'>Password</Label>
              <FormGroup>
                <Input
                  type="password"
                  name="password"
                  value={form.password}
                  placeholder="Password"
                  onChange={handleChange}
                />
              </FormGroup>
              <div className="mt-5">
                <Button
                  type="submit"
                  className="primary-btn w-100 pt-3 pb-3 pl-4 pr-4 font-weight-bold"
                  onClick={handleSignIn}
                  disabled={loginInProgress || !valid}
                >
                  <SpinnerInButton loading={loginInProgress} text="Log in" />
                  {loginInProgress && (<span className="ml-3">Logging in</span>)}
                </Button>
              </div>
              <div className='pt-5'>
                <p>
                  No account?{" "}
                  <span>
                    <Link to="/signup" className='font-weight-bold link-text-secondary'>Create an account</Link>
                  </span>
                </p>
              </div>
            </Form>
          </Container>
        </Col>
        <Col xl={4} lg={3} md={2} sm={12} xs={12}></Col>
      </Row>
    </>
  );
};
