import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
} from 'reactstrap';
import ErrorMessageList from '../shared/ErrorMessageList';
import AuthClient from '../../utils/AuthClient';
import GraphqlClient from '../../utils/GraphqlClient';
import SpinnerInButton from '../shared/SpinnerInButton';

const RegisterAdminPage = () => {
  const history = useHistory();
  const [errors, setErrors] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [valid, setValid] = useState(false);
  const [newUser, setNewUser] = useState({
    email: "",
    name: "",
    phone_number: "",
    password: "",
    user: null, // will contain our user data object when signed in
  });

  useEffect(() => {
    const valid = (() => {
      return !!newUser.email && !!newUser.phone_number && !!newUser.password;
    })();
    setValid(valid);
    // eslint-disable-next-line
  }, [
    newUser.email,
    newUser.phone_number,
    newUser.password
  ]);

  const handleChange = event => {
    const { name, value } = event.target;
    const newForm = {
      ...newUser,
      [name]: value
    };
    setNewUser(newForm);
  };

  const handleSignUp = async event => {
    event.preventDefault();
    setInProgress(true);
    const { email, phone_number, name, password } = newUser;
    try {
      const data = await AuthClient.signUp({
        username: email,
        password,
        name,
        phone_number,
        email,
        validationData: []
      });
      await registerNewUser(data.userSub, email, phone_number, name);
      history.push(`/login`);
    } catch (err) {
      errors.push(err.message ? err.message : err);
      setErrors(errors);
      setInProgress(false);
    }
  };

  const registerNewUser = async (id, email, phoneNumber, fullName) => {
    try {
      const dataInput = {
        id,
        username: email,
        email,
        fullName,
        phoneNumber,
        userType: "admin"
      };
      await GraphqlClient.registerUser(dataInput);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error registering new user", err);
    }
  };

  return (
    <Container className='pt-5 pb-5'>
      <h1>Admin Sign Up</h1>
      <div className='divider mb-5'></div>
      <ErrorMessageList errors={errors} />
      <Form>
        <Label className="font-weight-bold">Full name</Label>
        <FormGroup>
          <Input
            type="text"
            name="name"
            value={newUser.name}
            onChange={handleChange}
          />
        </FormGroup>

        <Label className="font-weight-bold mt-3">Phone number</Label>
        <FormGroup>
          <Input
            type="text"
            name="phone_number"
            value={newUser.phone_number}
            onChange={handleChange}
          />
        </FormGroup>

        <Label className="font-weight-bold mt-3">Email address</Label>
        <FormGroup>
          <Input
            type="email"
            name="email"
            value={newUser.email}
            onChange={handleChange}
          />
        </FormGroup>

        <Label className="font-weight-bold mt-3">Password</Label>
        <FormGroup>
          <Input
            type="password"
            name="password"
            value={newUser.password}
            onChange={handleChange}
          />
        </FormGroup>

        <div className="mt-5 text-right">
          <Button
            className="primary-btn"
            onClick={handleSignUp}
            disabled={inProgress || !valid}
          >
            <SpinnerInButton loading={inProgress} text="Sign up" />
            {inProgress && (<span className="ml-3">Signing up</span>)}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default RegisterAdminPage;