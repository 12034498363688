import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './App.scss';
import NoAuthRoutes from './routes/NoAuthRoutes';
import AuthRoutes from './routes/AuthRoutes';
import { User } from './models/User';
import { modelFactory } from './models/ModelFactory';
import { Hub } from 'aws-amplify';
import AuthClient from './utils/AuthClient';
import GraphqlClient from './utils/GraphqlClient';
import { UserContext } from './UserContext';
import { CommonRoutes } from './routes/CommonRoutes';

function App() {
  const [user, setUser] = useState<User>(null);
  const [userData, setUserDataState] = useState<User>(null);
  const setUserData = useCallback((userData: User) => {
    const userdata = userData ?? {};
    const user = modelFactory<User>(userdata, User);
    setUserDataState(user);
  }, []);
  const value = useMemo(() => ({ userData, setUserData }), [userData, setUserData]);

  useEffect(() => {
    getUserData();
    Hub.listen("auth", (data) => {
      const { payload } = data;
      onAuthEvent(payload);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserData = async () => {
    try {
      const user = await AuthClient.currentAuthenticatedUser();
      const result = await GraphqlClient.getUser(user.attributes.sub);
      const userData = result.data.getUser;
      if (userData) {
        setUserData(userData);
      } else {
        const input = {
          id: user.attributes.sub,
          username: user.username,
          fullName: user.attributes.fullName,
          email: user.attributes.email,
          userType: "admin"
        };
        const response = await GraphqlClient.registerUser(input);
        setUserData(response.data.registerUser);
      }
      setUser(user);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err, "not logged in");
    }
  };

  const onAuthEvent = payload => {
    switch (payload.event) {
    case "signIn":
      getUserData();
      break;
    case "signUp":
      break;
    case "signOut":
      setUser(null);
      setUserData(null);
      break;
    default:
      return;
    }
  };

  return (
    <>
      <UserContext.Provider value={value}>
        <div className="App">
          {
            user ? (
              <div className="user">
                <AuthRoutes />
              </div>
            ) : (
              <>
                <NoAuthRoutes />
              </>
            )
          }
          <CommonRoutes />
        </div>
      </UserContext.Provider>
    </>
  );
}

export default App;
