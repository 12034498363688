import React from 'react';
import { Route } from 'react-router-dom';
import AllVendorsPage from '../components/vendor/AllVendorsPage';
import { VendorDetailsPage } from '../components/vendor/VendorDetailsPage';
import LandingPage from '../components/landing/LandingPage';
import { ProductDetailsPage } from '../components/product/ProductDetailsPage';

export const CommonRoutes = () => {
  return (
    <>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/shops" component={AllVendorsPage} />
      <Route exact path="/shops/:id" component={VendorDetailsPage} />
      <Route exact path="/products/:id" component={ProductDetailsPage} />
    </>
  );
};

export const CommonRoutesInUser = () => {
  return (
    <>
    </>
  );
};
