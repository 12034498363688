export class User {
  private json: any;
  id: string;
  username: string;
  email: string;
  fullName: string;
  phoneNumber: string;
  userType: string;
  vendorId: string;
  stripeAccountId: string;

  constructor(json) {
    this.json = json;
  }

  isAdmin() {
    return this.userType === "admin";
  }

}
