import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import { OrderModel } from '../../models/Order';

type Props = {
  order: OrderModel;
  index: number;
}

export const UserOrderListTable = ({ order, index }: Props) => {

  // const serviceFee = Calculations.calculateServiceFee(order.wholesalePrice);
  // const totalServiceFee = order.wholesalePrice + serviceFee;

  return(
    <>
      <tr>
        <td className='text-center'>{index + 1}</td>
        <td className="text-uppercase font-weight-bold">{order.title}</td>
        <td className='text-center'>
          {(!order.shippingStatus || (order.shippingStatus === "pending")) && (<Badge color='warning'>PENDING</Badge>)}
          {(order.shippingStatus === "shipped") && (<Badge color='primary'>SHIPPED</Badge>)}
          {(order.shippingStatus === "delivered") && (<Badge color='primary'>DELIVERED</Badge>)}
          {(order.shippingStatus === "unsuccessful") && (<Badge color='danger'>DELIVERY ATTEMPTED</Badge>)}
        </td>
        <td className='text-center'>{order.wholesalePrice.toFixed(2)}</td>
        <td className='text-center'>{order.quantity}</td>
        <td className='text-center'>{order.shippingFee}</td>
        <td className='text-center'>{order.totalPrice.toFixed(2)}</td>
        <td className="text-center">
          <Link to={`/products/${order.productId}`} rel="noopener noreferrer" target="_blank">
            <Button
              className="white-btn"
              style={{ borderRadius: "10px" }}
            >
              View
            </Button>
          </Link>
        </td>
      </tr>
    </>
  )
}