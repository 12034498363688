import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, FormGroup, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import GraphqlClient from '../../utils/GraphqlClient';
import { modelFactory } from '../../models/ModelFactory';
import { UserContext } from '../../UserContext';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { VendorModel } from '../../models/Vendor';
import { ProductModel } from '../../models/Product';
import Calculations from '../../utils/Calculations';
import { OrderConfirmationModal } from '../order/OrderConfirmationModal';
import LoadingSpinner from '../shared/LoadingSpinner';
import AuthNavbar from '../header/AuthNavbar';
import Header from '../header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { EditProductModal } from './EditProductModal';
import SpinnerInButton from '../shared/SpinnerInButton';
import { ArchiveProductModal } from './ArchiveProductModal';

interface Props extends RouteComponentProps<{ id: string; }> {
}

export const ProductDetailsPage: React.FC<Props> = (props) => {
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [errors, setErrors] = useState([]);
  const [vendor, setVendor] = useState<VendorModel>(undefined);
  const [product, setProduct] = useState<ProductModel>(undefined);
  // const [totalPrice, setTotalPrice] = useState(0);
  const [totalServiceFee, setTotalServiceFee] = useState(0);
  let [orderCount, setOrderCount] = useState(1);
  const [form, setForm] = useState({
    notesToVendor: ""
  });
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);

  const toggleProductModal = () => {
    setOpenProductModal(!openProductModal);
  };

  const toggleArchiveModal = () => {
    setOpenArchiveModal(!openArchiveModal);
  };

  const toggleOrderModal = () => {
    setOpenOrderModal(!openOrderModal);
    setSubmitInProgress(!submitInProgress);
  };

  const toggleCancelModal = () => {
    setSubmitInProgress(!submitInProgress);
  };

  const handleChange = event => {
    const { name, value, type } = event.target;
    const newForm = {
      ...form,
      [name]: type === 'number' ? parseFloat(value) : value
    };
    setForm(newForm);
  };

  const handleIncrement = event => {
    orderCount = orderCount + 1;
    setOrderCount(orderCount);
    const totalPrice = Calculations.calculateOrder(product.wholesalePrice, orderCount);
    // const serviceFee = Calculations.calculateTotalNoServiceFee(totalPrice);
    const totalServiceFee = totalPrice;
    setTotalServiceFee(totalServiceFee);
  };

  const handleDecrement = event => {
    orderCount = orderCount - 1;
    setOrderCount(orderCount);
    const totalPrice = Calculations.calculateOrder(product.wholesalePrice, orderCount);
    // const serviceFee = Calculations.calculateTotalNoServiceFee(totalPrice);
    const totalServiceFee = totalPrice;
    setTotalServiceFee(totalServiceFee);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      (async () => {
        const productId = props.match.params.id;
        const result = await GraphqlClient.getProduct(productId);
        const product = modelFactory<ProductModel>(
          result.data.getProduct,
          ProductModel
        );
        setProduct(product);
        // const serviceFee = Calculations.calculateTotalNoServiceFee(product.wholesalePrice);
        const totalServiceFee = product.wholesalePrice;
        setTotalServiceFee(totalServiceFee);
      })();
    } catch (err) {
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  }, [props.match.params.id, errors, history, userData]);

  useEffect(() => {
    if (!product) {
      return;
    }
    window.scrollTo(0, 0);
    try {
      (async () => {
        const vendorId = product.vendorId;
        const filter = {
          id: { eq: vendorId }
        };
        const results = await Promise.all([
          GraphqlClient.listVendors(filter)
        ]);
        const vendors = results[0].map(i =>
          modelFactory<VendorModel>(i, VendorModel)
        );
        setVendor(vendors[0]);
      })();
    } catch (err) {
      const newError = err.errors ? err.errors.map(error => error.message) : err;
      setErrors([...errors, newError]);
    }
  }, [product, errors]);

  if (!product || !vendor) {
    return <LoadingSpinner />
  }

  return(
    <>
      {userData && (
        <AuthNavbar />
      )}
      {!userData && (
        <Header />
      )}
      <Container className='pt-5 mt-5 pb-5'>
        <h2 style={{ color: '#000', fontFamily: '"Montserrat", sans-serif', fontSize: '40px', fontWeight: 600 }} className='pt-5 mt-5'>{product.title}</h2>
        <div className='divider mb-3'></div>
        <Row className='pt-5'>
          <Col md={12} className='pb-5'>
            {(product.productPhoto && product.productPhoto2 && product.productPhoto3 && product.productPhoto4) && (
              <Row>
                <Col md={6} className='pb-5'>
                  {(product) && (
                    <>
                      <img
                        src={`https://${product.productPhoto.bucket}.s3.amazonaws.com/public/${product.productPhoto.key}`} 
                        alt={product.title}
                        style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
                      />
                    </>
                  )}
                </Col>
                <Col md={6} className='pb-5'>
                  {(product) && (
                    <>
                      <img
                        src={`https://${product.productPhoto2.bucket}.s3.amazonaws.com/public/${product.productPhoto2.key}`} 
                        alt={product.title}
                        style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
                      />
                    </>
                  )}
                </Col>
                <Col md={6} className='pb-5'>
                  {(product) && (
                    <>
                      <img
                        src={`https://${product.productPhoto3.bucket}.s3.amazonaws.com/public/${product.productPhoto3.key}`} 
                        alt={product.title}
                        style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
                      />
                    </>
                  )}
                </Col>
                <Col md={6} className='pb-5'>
                  {(product) && (
                    <>
                      <img
                        src={`https://${product.productPhoto4.bucket}.s3.amazonaws.com/public/${product.productPhoto4.key}`} 
                        alt={product.title}
                        style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
                      />
                    </>
                  )}
                </Col>
              </Row>
            )}
            {(product.productPhoto && product.productPhoto2 && product.productPhoto3 && !product.productPhoto4) && (
              <Row>
                <Col md={4} className='pb-5'>
                  {(product) && (
                    <>
                      <img
                        src={`https://${product.productPhoto.bucket}.s3.amazonaws.com/public/${product.productPhoto.key}`} 
                        alt={product.title}
                        style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
                      />
                    </>
                  )}
                </Col>
                <Col md={4} className='pb-5'>
                  {(product) && (
                    <>
                      <img
                        src={`https://${product.productPhoto2.bucket}.s3.amazonaws.com/public/${product.productPhoto2.key}`} 
                        alt={product.title}
                        style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
                      />
                    </>
                  )}
                </Col>
                <Col md={4} className='pb-5'>
                  {(product) && (
                    <>
                      <img
                        src={`https://${product.productPhoto3.bucket}.s3.amazonaws.com/public/${product.productPhoto3.key}`} 
                        alt={product.title}
                        style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
                      />
                    </>
                  )}
                </Col>
              </Row>
            )}
            {(product.productPhoto && product.productPhoto2 && !product.productPhoto3 && !product.productPhoto4) && (
              <Row>
                <Col md={6} className='pb-5'>
                  {(product) && (
                    <>
                      <img
                        src={`https://${product.productPhoto.bucket}.s3.amazonaws.com/public/${product.productPhoto.key}`} 
                        alt={product.title}
                        style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
                      />
                    </>
                  )}
                </Col>
                <Col md={6} className='pb-5'>
                  {(product) && (
                    <>
                      <img
                        src={`https://${product.productPhoto2.bucket}.s3.amazonaws.com/public/${product.productPhoto2.key}`} 
                        alt={product.title}
                        style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
                      />
                    </>
                  )}
                </Col>
              </Row>
            )}
            {(product.productPhoto && !product.productPhoto2 && !product.productPhoto3 && !product.productPhoto4) && (
              <Row>
                <Col md={12} className='pb-5'>
                  {(product) && (
                    <>
                      <img
                        src={`https://${product.productPhoto.bucket}.s3.amazonaws.com/public/${product.productPhoto.key}`} 
                        alt={product.title}
                        style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }}
                      />
                    </>
                  )}
                </Col>
              </Row>
            )}
          </Col>
          <Col md={12} className='pb-5'>
            <Row className='pt-3'>
              <Col xs={6}>
                {userData && ((userData.id === vendor.vendorUserId) || (userData.userType === "admin")) && (
                  <div className='mt-3 text-center' onClick={toggleProductModal}>
                    <p style={{ cursor: 'pointer' }}>
                      <span className="mr-3"><FontAwesomeIcon icon={faEdit} /></span>
                        Edit
                    </p>
                  </div>
                )}
              </Col>
              <Col xs={6}>
                {userData && ((userData.id === vendor.vendorUserId) || (userData.userType === "admin")) && (
                  <div className='mt-3 text-center' onClick={toggleArchiveModal}>
                    <p style={{ cursor: 'pointer' }}>
                      <span className="mr-3"><FontAwesomeIcon icon={faTrash} /></span>
                        Archive
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={12} className='pb-5'>
            <p className='font-weight-bold grey-color' style={{ fontSize: '18px' }}>Shop name</p>
            <Link to={`/shops/${vendor.id}`} className='link-text-secondary'>
              <h3 style={{ fontSize: '20px', fontWeight: 500 }}>{vendor.name}</h3>
            </Link>

            <p className='font-weight-bold grey-color mt-5' style={{ fontSize: '18px' }}>Description</p>
            <h3 style={{ fontSize: '20px', fontWeight: 500 }}>{product.description}</h3>

            <p className='font-weight-bold grey-color mt-5' style={{ fontSize: '18px' }}>Weight</p>
            <h3 style={{ fontSize: '20px', fontWeight: 500 }}>{product.weight} kg</h3>

            <p className='font-weight-bold grey-color mt-5 mb-0' style={{ fontSize: '18px' }}>No. of units in 1 packaging</p>
            <h3 style={{ fontSize: '20px', fontWeight: 500 }}>
              {product.unitPerPackaging} units
            </h3>

            <p className='font-weight-bold grey-color mt-5 mb-0' style={{ fontSize: '18px' }}>Minimum order</p>
            <h3 style={{ fontSize: '20px', fontWeight: 500 }}>
              {product.minQuantity} packaging
            </h3>

            <p className='font-weight-bold grey-color mt-5' style={{ fontSize: '18px' }}>Wholesale price</p>
            <h3 style={{ fontSize: '20px', fontWeight: 500 }}>RM {product.wholesalePrice.toFixed(2)}</h3>

            {product.pricePerUnit && (
              <>
                <p className='font-weight-bold grey-color mt-5' style={{ fontSize: '18px' }}>Price Per Unit</p>
                <h3 style={{ fontSize: '20px', fontWeight: 500 }}>RM {product.pricePerUnit.toFixed(2)}</h3>
              </>
            )}

            <p className='font-weight-bold grey-color mt-5' style={{ fontSize: '18px' }}>Retail price Per Unit</p>
            <h3 style={{ fontSize: '20px', fontWeight: 500 }}>RM {product.retailPrice.toFixed(2)}</h3>

            <p className='font-weight-bold grey-color mt-5' style={{ fontSize: '18px' }}>Notes to {vendor.name} shop</p>
            {userData && (
              <FormGroup>
                <Input
                  type="textarea"
                  rows="7"
                  name="notesToVendor"
                  id="notesToVendor"
                  className="h-100"
                  value={form.notesToVendor}
                  onChange={handleChange}
                />
              </FormGroup>
            )}
            {!userData && (
              <p className='grey-color' style={{ fontSize: '20px' }}>
                Login to order this product.
                <span>
                  <Link to="/login" className='ml-2 font-weight-bold link-text-secondary'>Login here.</Link>
                </span>
              </p>
            )}

            <p className='font-weight-bold grey-color mt-5' style={{ fontSize: '18px' }}>How many you want to order?</p>
            <InputGroup>
              <InputGroupText style={{ cursor: 'pointer' }} onClick={handleDecrement}>-
              </InputGroupText>
              <Input value={orderCount} onChange={handleChange} />
              <InputGroupText style={{ cursor: 'pointer' }} onClick={handleIncrement}>+
              </InputGroupText>
            </InputGroup>

            <div className='text-right mt-5'>
              <h4 style={{ fontSize: '24px', fontWeight: 500 }}>Total Price: RM {totalServiceFee.toFixed(2)}</h4>

              <div className='mt-4'>
                {userData && (
                  <Button
                    onClick={toggleOrderModal}
                    className="primary-btn"
                    style={{ borderRadius: "10px" }}
                  >
                    <SpinnerInButton loading={submitInProgress} text="Confirm order" />
                    {submitInProgress && (<span className="ml-3">Redirecting</span>)}
                  </Button>
                )}
                {!userData && (
                  <Link to='/login'>
                    <Button
                      className="primary-btn"
                      style={{ borderRadius: "10px" }}
                    >
                      Login to order
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <OrderConfirmationModal
        open={openOrderModal}
        toggle={toggleOrderModal}
        vendor={vendor}
        product={product}
        notesToVendor={form.notesToVendor}
        orderQuantity={orderCount}
        submitInProgress={toggleCancelModal}
      />
      <EditProductModal 
        open={openProductModal}
        toggle={toggleProductModal}
        vendorId={vendor.id}
        product={product}
      />
      <ArchiveProductModal 
        open={openArchiveModal}
        toggle={toggleArchiveModal}
        vendorId={vendor.id}
        productId={product.id}
        title={product.title}
      />
    </>
  )
}