import React from 'react';
import { Route } from 'react-router-dom';
import RegisterAdminPage from '../components/admin/RegisterAdminPage';
import { LoginPage } from '../components/account/LoginPage';
import SignUpPage from '../components/account/SignUpPage';
import SignUpVendorPage from '../components/account/SignUpVendorPage';
import { VerifyEmailPage } from '../components/account/VerifyEmailPage';

const NoAuthRoutes = () => {
  return (
    <>
      <Route exact path="/register-admin" component={RegisterAdminPage} />
      <Route exact path="/signup" component={SignUpPage} />
      <Route exact path="/vendor-signup" component={SignUpVendorPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/verify-your-email" component={VerifyEmailPage} />
      
    </>
  );
};

export default NoAuthRoutes;